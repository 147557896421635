<template>
  <div>
    <div class="text-center q-mb-lg">
      <h3 class="q-ma-none text-h4">
        {{ $t('Settings') }}
      </h3>
    </div>

    <div class="row q-mb-sm full-width">
      <div class="multisteps-form full-width">
        <div class="multisteps-form__progress">
          <button
            v-for="btn in tabButtons"
            :key="btn.id"
            :class="btn.className"
            @click="btn.onClick"
          >
            {{ $t(btn.title) }}
          </button>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div v-if="activeTab === 'measurements'">
        <div v-if="queue">
            <form-builder :schema="measurementsSchema" />
        </div>
        <div v-else>
            <p class="alert-danger q-py-sm text-center">
                {{ $t('First Select Queue') }}
            </p>
        </div>
      </div>

      <div v-if="activeTab === 'workflow'">
        <div v-if="queue">

          <p class="alert-danger q-py-sm text-center">
            {{ $t('Do not change workflow if you are not sure') }}
          </p>

          <form-builder :schema="workflowSchema" />

        </div>
        <div v-else>
          <p class="alert-danger q-py-sm text-center">
            {{ $t('First Select Delivery Request') }}
          </p>
        </div>
      </div>

      <div v-if="activeTab === 'queue'">
        <div v-if="queue">
            <form-builder :schema="queueSchema" />
        </div>
        <div v-else>
            <p class="alert-danger q-py-sm text-center">
                {{ $t('First Select Delivery Request') }}
            </p>
        </div>
      </div>
    </div>

    <div class="row justify-end items-center q-px-md">
      <q-btn
        type="button"
        color="light-blue-9"
        text-color="white"
        :label="$t('OK')"
        no-caps
        @click="handleClose"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'PackagingSettings',
  emits: ['close'],
  data () {
    return {
      activeTab: 'workflow',
      tabs: ['workflow', 'measurements', 'queue'],
      generateShippingManifest: false,
      loadRequestsAutomatically: false,
      showMeasurementsInput: false,
      immediatelySubmit: false,
      acceptanceControl: false,
      consumptionControl: false,
      weightControl: false,
      disablePackingInterface: false,
      maxWeight: 14000,
      consumption: null,
      checkParcelContent: false,
      trustIncomingMeasurements: false,
      setPredefinedMeasurements: false,
      isEnabledDeliveryRequestTable: false,
      default: {
        weight: 1000,
        width: 100,
        length: 100,
        height: 100,
        sendDate: null
      },
    }
  },
  watch: {
    queue(newValue) {
      if(newValue.settings['generate-shipping-manifest'] ) {
        this.generateShippingManifest = newValue.settings['generate-shipping-manifest']
      }
      if( newValue.settings['load-requests-automatically']) {
        this.loadRequestsAutomatically = newValue.settings['load-requests-automatically']
      }
      if( newValue.settings['show-measurements-input']) {
        this.showMeasurementsInput = newValue.settings['show-measurements-input']
      }else {
        this.showMeasurementsInput = false
      }
      if( newValue.settings.dimensions) {
        if(newValue.settings.dimensions.weight) {
          this.default.weight = newValue.settings.dimensions.weight
        }
        if(newValue.settings.dimensions.width) {
          this.default.width = newValue.settings.dimensions.width
        }
        if(newValue.settings.dimensions.length) {
          this.default.length = newValue.settings.dimensions.length
        }
        if(newValue.settings.dimensions.height) {
          this.default.height = newValue.settings.dimensions.height
        }
        if(newValue.settings.dimensions.weight) {
          this.default.sendDate = newValue.settings.dimensions.sendDate
        }
      }
      if( newValue.settings['acceptance-control']) {
        this.acceptanceControl = newValue.settings['acceptance-control']
      }
      if(newValue.settings['weight-control']) {
        this.weightControl = newValue.settings['weight-control']
      }
      if(newValue.settings['consumption-control']) {
        this.consumptionControl = newValue.settings['consumption-control']
      }
      if(newValue.settings['max-weight']) {
        this.maxWeight = newValue.settings['max-weight']
      }
      if(newValue.settings.consumption){
        this.consumption = newValue.settings.consumption
      }
      if(newValue.settings['check-parcel-content']) {
        this.checkParcelContent = newValue.settings['check-parcel-content']
      }
      if(newValue.settings['trust-incoming-measurements']) {
        this.trustIncomingMeasurements = newValue.settings['trust-incoming-measurements']
      }
      if(newValue.settings['set-predefined-measurements']) {
        this.setPredefinedMeasurements = newValue.settings['set-predefined-measurements']
      }
      if(newValue.settings['is-enabled-delivery-request-table']){
        this.isEnabledDeliveryRequestTable = newValue.settings['is-enabled-delivery-request-table']
      }
      if(newValue.settings['immediately-submit']) {
        this.immediatelySubmit = newValue.settings['immediately-submit']
      }
      if(newValue.settings['disable-packing-interface']) {
        this.disablePackingInterface = newValue.settings['disable-packing-interface']
      }
    },
  },
  mounted() {
    if(this.queue && this.queue.settings) {
      if(this.queue.settings['generate-shipping-manifest'] ) {
        this.generateShippingManifest = this.queue.settings['generate-shipping-manifest']
      }
      if( this.queue.settings['load-requests-automatically']) {
        this.loadRequestsAutomatically = this.queue.settings['load-requests-automatically']
      }
      if( this.queue.settings['show-measurements-input']) {
        this.showMeasurementsInput = this.queue.settings['show-measurements-input']
      }
      if( this.queue.settings.dimensions) {
        if(this.queue.settings.dimensions.weight) {
          this.default.weight = this.queue.settings.dimensions.weight
        }
        if(this.queue.settings.dimensions.width) {
          this.default.width = this.queue.settings.dimensions.width
        }
        if(this.queue.settings.dimensions.length) {
          this.default.length = this.queue.settings.dimensions.length
        }
        if(this.queue.settings.dimensions.height) {
          this.default.height = this.queue.settings.dimensions.height
        }
        if(this.queue.settings.dimensions.weight) {
          this.default.sendDate = this.queue.settings.dimensions.sendDate
        }
      }
      if( this.queue.settings['acceptance-control']) {
        this.acceptanceControl = this.queue.settings['acceptance-control']
      }
      if(this.queue.settings['weight-control']) {
        this.weightControl = this.queue.settings['weight-control']
      }
      if(this.queue.settings['consumption-control']) {
        this.consumptionControl = this.queue.settings['consumption-control']
      }
      if(this.queue.settings['max-weight']) {
        this.maxWeight = this.queue.settings['max-weight']
      }
      if(this.queue.settings.consumption){
        this.consumption = this.queue.settings.consumption
      }
      if(this.queue.settings['check-parcel-content']) {
        this.checkParcelContent = this.queue.settings['check-parcel-content']
      }
      if(this.queue.settings['trust-incoming-measurements']) {
        this.trustIncomingMeasurements = this.queue.settings['trust-incoming-measurements']
      }
      if(this.queue.settings['set-predefined-measurements']) {
        this.setPredefinedMeasurements = this.queue.settings['set-predefined-measurements']
      }
      if(this.queue.settings['is-enabled-delivery-request-table']){
        this.isEnabledDeliveryRequestTable = this.queue.settings['is-enabled-delivery-request-table']
      }
      if(this.queue.settings['immediately-submit']) {
        this.immediatelySubmit = this.queue.settings['immediately-submit']
      }
      if(this.queue.settings['disable-packing-interface']) {
        this.disablePackingInterface = this.queue.settings['disable-packing-interface']
      }
    }
  },
  computed: {
    ...mapGetters([
      'settings',
      'queue',
      'deliveryRequest',
    ]),
    tabButtons () {
      const btnClass = 'multisteps-form__progress-btn'

      return this.tabs.map((tab, index) => {
        return {
          id: index,
          className: this.activeTab === tab ? btnClass + ' step-active' : btnClass,
          onClick: () => this.handleTabChange(tab),
          title: tab[0].toUpperCase() + tab.slice(1).toLowerCase()
        }
      })
    },
    queueSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'switch',
                label: this.$t('Generate shipping manifest'),
                value: this.generateShippingManifest,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: generateShippingManifest => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['generate-shipping-manifest']: generateShippingManifest
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.generateShippingManifest = generateShippingManifest
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Load requests automatically'),
                value: this.loadRequestsAutomatically,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: loadRequestsAutomatically => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['load-requests-automatically']: loadRequestsAutomatically
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.loadRequestsAutomatically = loadRequestsAutomatically
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Show measurements input'),
                value: this.showMeasurementsInput,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: showMeasurementsInput => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['show-measurements-input']: showMeasurementsInput
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                      .then(()=> {
                        this.showMeasurementsInput = showMeasurementsInput
                      })
                }
              },
            ]
          }
        ]
      }
    },
    measurementsSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'date',
                label: this.$t('Default send date'),
                value: this.default.sendDate,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: sendDate => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      dimensions: {
                        ...this.queue.settings.dimensions,
                        sendDate: sendDate
                      }

                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.default.sendDate = sendDate
                      this.updateSettingsDefault({ sendDate })
                    })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Weight (gramm)'),
                value: this.default.weight,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: weight => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      dimensions: {
                        ...this.queue.settings.dimensions,
                        weight: weight
                      }

                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.default.weight = weight
                      this.updateSettingsDefault({ weight })
                    })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Width (mm)'),
                value: this.default.width,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: width => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      dimensions: {
                        ...this.queue.settings.dimensions,
                        width: width
                      }

                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.default.width = width
                      this.updateSettingsDefault({ width })
                    })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Height (mm)'),
                value: this.default.height,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: height => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      dimensions: {
                        ...this.queue.settings.dimensions,
                        height: height
                      }

                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.default.height = height
                      this.updateSettingsDefault({ height })
                    })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Length (mm)'),
                model: 'default.length',
                value: this.default.length,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: length => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      dimensions: {
                        ...this.queue.settings.dimensions,
                        length: length
                      }

                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.default.length = length
                      this.updateSettingsDefault({ length })
                    })
                }
              }
            ]
          }
        ]
      }
    },
    workflowSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'switch',
                label: this.$t('Immediately Submit'),
                value: this.immediatelySubmit,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('If it\'s on, we will automatically submit sizes after you enter them.'),
                onChange: immediatelySubmit => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['immediately-submit']: immediatelySubmit
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.immediatelySubmit = immediatelySubmit
                      this.updateSettings({ immediatelySubmit })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Acceptance'),
                value: this.acceptanceControl,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('If it\'s off, acceptance will not be required'),
                onChange: acceptanceControl => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['acceptance-control']: acceptanceControl
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.acceptanceControl = acceptanceControl
                      this.updateSettings({ acceptanceControl })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Trust incoming measurements'),
                value: this.trustIncomingMeasurements,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: trustIncomingMeasurements => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['trust-incoming-measurements']: trustIncomingMeasurements
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.trustIncomingMeasurements = trustIncomingMeasurements
                      this.updateSettings({ trustIncomingMeasurements })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Set predefined measurements'),
                value: this.setPredefinedMeasurements,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: setPredefinedMeasurements => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['set-predefined-measurements']: setPredefinedMeasurements
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.setPredefinedMeasurements = setPredefinedMeasurements
                      this.updateSettings({ setPredefinedMeasurements })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Purchase Order'),
                value: this.consumptionControl,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('If it\'s off, state of the request will be automatically set to sent'),
                onChange: consumptionControl => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['consumption-control']: consumptionControl
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.consumptionControl = consumptionControl
                      this.updateSettings({ consumptionControl })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Shipping Requests Table'),
                value: this.isEnabledDeliveryRequestTable,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('If it\'s on, there will be added table with shipping requests.'),
                onChange: isEnabledDeliveryRequestTable => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['is-enabled-delivery-request-table']: isEnabledDeliveryRequestTable
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.isEnabledDeliveryRequestTable = isEnabledDeliveryRequestTable
                      this.updateSettings({ isEnabledDeliveryRequestTable })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Max Weight'),
                value: this.weightControl,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('If it\'s on, there will be have max weight check'),
                onChange: weightControl => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['weight-control']: weightControl
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.weightControl = weightControl
                      this.updateSettings({ weightControl })
                    })
                }
              },
              {
                type: 'switch',
                label: this.$t('Disable Packing Interface'),
                value: this.disablePackingInterface,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('If it\'s on, all input boxes will be disabled'),
                onChange: disablePackingInterface => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['disable-packing-interface']: disablePackingInterface
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.disablePackingInterface = disablePackingInterface
                      this.updateSettings({ disablePackingInterface })
                    })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Weight (gramm)'),
                value: this.maxWeight,
                disabled: !this.settings.weightControl,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: maxWeight => {
                  const data = {
                    settings: {
                      ...this.queue.settings,
                      ['max-weight']: maxWeight
                    }
                  }
                  return this.$service.preprocessingQueue.save(data, this.queue.id)
                    .then(()=> {
                      this.maxWeight = maxWeight
                      this.updateSettings({ maxWeight })
                    })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions([
      'loadDeliveryRequest'
    ]),
    ...mapMutations([
      'updateSettings',
      'updateSettingsDefault'
    ]),
    handleClose () {
      this.loadDeliveryRequest(this.deliveryRequest.id)
      this.$emit('close')
    },
    handleTabChange (tab) {
      this.activeTab = tab
    }
  }
}
</script>

<template>
  <div class="shadow bg-white overflow-auto rounded text-dark">
    <div v-if="!hideAmount">
      <div
        v-if="itemsSum.scannedCount > 0 && itemsSum.countDifference !== 0"
        class="row items-center justify-center text-subtitle1 q-py-sm"
        @click="handleExpand"
      >
        <div class="border border-gray rounded q-pa-xs">
          <strong>
            {{ originalSum.scannedCount || 0 }}
          </strong>
        </div>

        <div class="q-pa-xs">
          <span v-if="originalSum.scannedCount < itemsSum.scannedCount">
            +
          </span>

          <span v-else>
            -
          </span>
        </div>

        <div class="border border-warning rounded q-pa-xs">
          <strong>
            {{ itemsSum.countDifference }}
          </strong>
        </div>

        <div class="q-pa-xs">
          =
        </div>

        <div class="border border-success rounded q-pa-xs">
          <strong>
            {{ itemsSum.scannedCount || 0 }}
          </strong>
        </div>

        <div class="q-pa-xs">
          <strong>
            {{ '/' + itemsSum.count }}
          </strong>
        </div>
      </div>

      <div
        v-else
        class="text-center text-subtitle1 q-py-sm"
        @click="handleExpand"
      >
        <strong>
          {{ itemsSum.scannedCount + '/' + itemsSum.count }}
        </strong>
      </div>
    </div>

    <div v-if="isExpanded">
      <q-table
        row-key="id"
        class="table--only-body"
        :rows-per-page-label="$t('Rows per page')"
        :rows="tasks"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: data.length }"
        virtual-scroll
        binary-state-sort
        flat
      >
        <template v-slot:body="props">
          <dynamic-tasks-row
            :data="props"
            @click="handleClick"
          />
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
// Components
import DynamicTasksRow from './dynamic-tasks-row.vue'

export default {
  name: 'DynamicTasksCollection',
  components: {
    DynamicTasksRow
  },
  props: {
    hideAmount: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isExpanded: false,
      tasks: [],
      columns: [
        { label: this.$t('Offer'), name: 'offer', align: 'left' },
        { label: this.$t('Quantity'), name: 'quantity', align: 'center' }
      ],
      originalSum: {
        scannedCount: 0,
        count: 0,
        countDifference: 0
      },
      itemsSum: {
        scannedCount: 0,
        count: 0,
        countDifference: 0
      }
    }
  },
  watch: {
    data: {
      handler (value) {
        this.itemsSum = this.getSum(value, this.originalSum.scannedCount)
        this.isExpanded = !!this.data[0] && !!this.data[0]._embedded && !!this.data[0]._embedded.productOffer

        if (this.isExpanded) {
          this.tasks = this.getReducedTasks()
        } else {
          this.tasks = this.data
        }
      },
      deep: true
    }
  },
  mounted () {
    this.originalSum = {
      ...this.getSum(this.data),
      countDifference: 0
    }

    this.itemsSum = { ...this.originalSum }

    if (this.data[0] && this.data[0]._embedded && this.data[0]._embedded.productOffer) {
      this.tasks = this.getReducedTasks()
    } else {
      this.tasks = this.data
    }

    this.isExpanded = !!this.data[0] && !!this.data[0]._embedded && !!this.data[0]._embedded.productOffer
  },
  methods: {
    getReducedTasks () {
      return this.data.reduce((acc, task) => {
        let isExist = false

        const tasks = acc.map(t => {
          if (t._embedded.productOffer.id === task._embedded.productOffer.id) {
            isExist = true

            return {
              ...t,
              count: t.count + task.count,
              scannedCount: t.scannedCount + task.scannedCount
            }
          }

          return t
        })

        if (isExist) {
          return tasks
        }

        acc.push(task)
        return acc
      }, [])
    },
    handleClick (task) {
      this.$emit('click', task)
    },
    handleExpand () {
      this.isExpanded = !this.isExpanded
    },
    getSum (data, secondCount = 0) {
      const sum = data.reduce((acc, item) => {
        acc.scannedCount += Number(item.scannedCount || 0)
        acc.count += Number(item.count || 0)

        return acc
      }, { scannedCount: 0, count: 0, countDifference: 0 })

      sum.countDifference = sum.scannedCount - secondCount

      return sum
    }
  }
}
</script>

<template>
    <q-table
        class="sticky-header-table"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        virtual-scroll
        binary-state-sort
        flat
        @request="handleBoxesRequest"
    >
        <template v-slot:body="props">
            <q-tr
                :props="props"
            >
                <q-td
                    key="number"
                    :props="props"
                    style="width: 5%"
                >
                    {{ props.rowIndex + 1 }}
                </q-td>

                <q-td
                    key="image"
                    :props="props"
                    style="width: 5%"
                >
                    <div @click="showImageZoomDialog(props.row.image || fallbackImage)">
                        <img
                            :src="props.row.image || fallbackImage"
                            style="width: 60px; height: 60px; object-fit: contain;"
                            @error="onImageLoadFailure"
                        >
                    </div>

                    <q-dialog v-model="imageZoomDialog" persistent>
                        <div @click="closeImageZoomDialog" style="object-fit: contain;">
                            <img :src="zoomedSrc">
                        </div>
                    </q-dialog>
                </q-td>

                <q-td
                    key="id"
                    :props="props"
                    style="width: 75%; white-space: pre-wrap"
                >
                    <span class="row"> {{'ID: ' + props.row.id}}</span>
                </q-td>

                <q-td
                    key="dimensions"
                    :props="props"
                    auto-width
                >
                    {{'Weight: ' + (props.row.weight ? props.row.weight: '0') + appOptions.defaultDimensions.weightUnit}}
                    <br>
                    {{'Width: ' + (props.row.dimensions ? props.row.dimensions.x : '0') + appOptions.defaultDimensions.dimensionUnit}}
                    <br>
                    {{'Height: ' + (props.row.dimensions ? props.row.dimensions.y: '0') + appOptions.defaultDimensions.dimensionUnit}}
                    <br>
                    {{'Length: ' + (props.row.dimensions ? props.row.dimensions.z: '0') + appOptions.defaultDimensions.dimensionUnit}}
                </q-td>
            </q-tr>
        </template>
    </q-table>
</template>


<script>
import {mapGetters} from "vuex";

export default {
  name: 'PalletPoolRow',
  props: {
    data: {
      type: Object,
      default() {
          return null
      }
    },
    deliveryRequest: {
      type: [String, Number],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      items: [],
      imageZoomDialog: false,
      zoomedSrc: '',
      isLoading: false,
      fallbackImage: './assets/img/fallback-image/package.png',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('ID'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Dimensions'),
          name: 'dimensions',
          align: 'left'
        },
      ],
    }
  },
  mounted() {
    this.handleBoxesRequest()
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
  },
  methods: {
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleBoxesRequest () {
      if(this.items.length > 0) {
        return
      }

      let query = null
      if (!this.data) {
        query = {
          filter: [
            { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest },
            { type: 'isnull', field: 'parent'}
          ],
          'order-by': [
            { type: 'field', field: 'priority', direction: 'asc' }
          ],
          'per_page': 25
        }
      } else {
        query = {
          filter: [
            { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest },
            { type: 'eq', field: 'parent', value: this.data.id}
          ],
          'order-by': [
            { type: 'field', field: 'priority', direction: 'asc' }
          ],
          'per_page': 25
        }
      }

      return this.$service.deliveryServicePlace.getAll(query)
        .then(({ items , page , totalItems}) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
          this.items = items
        })
    },
    showImageZoomDialog (src) {
      this.zoomedSrc = src
      this.imageZoomDialog = true
    },
    closeImageZoomDialog () {
      this.zoomedSrc = ''
      this.imageZoomDialog = false
    },
  }
}
</script>
<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 65vw; min-height: 80vh;">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
            {{ $t('Services') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <q-splitter
          v-model="splitterModel"
          style="height: 100%;"
        >
          <template v-slot:before>
            <q-tabs
              v-model="currentTab"
              vertical
              class="text-teal"
            >
              <q-tab
                name="deliveryRequest"
                :label="$t('Delivery Request')"
              />

              <q-tab
                name="order"
                :disable="!order"
                :label="$t('Order')"
              />
            </q-tabs>
          </template>

          <template v-slot:after>
            <q-tab-panels
              v-model="currentTab"
              animated
              swipeable
              vertical
              transition-prev="jump-up"
              transition-next="jump-up"
            >
              <q-tab-panel name="deliveryRequest">
                <q-tabs
                  v-model="dataTab"
                  class="text-teal"
                >
                    <q-tab
                      name="supplyList"
                      :label="$t('Supply')"
                    />

                    <q-tab
                      name="addedServicesList"
                      :label="$t('Added services list')"
                    />
                </q-tabs>
                <q-separator/>
                <q-tab-panels v-model="dataTab" animated>
                  <q-tab-panel name="supplyList">
                    <q-card-section class="q-pa-none">
                      <supplies-table-labelling
                        flat
                        :ext-filter="extFilter"
                        :deliveryRequest="deliveryRequest"
                        :services="services"
                        @on-dblclick="save"
                        @changeServices="handleChangeServices"
                      />
                    </q-card-section>
                  </q-tab-panel>

                  <q-tab-panel name="addedServicesList">
                    <q-card-section class="text-center">
                      <added-supplies-table
                        :deliveryRequest="deliveryRequest"
                        :services="services"
                        @changeServices="handleChangeServices"
                      />
                    </q-card-section>
                  </q-tab-panel>
                </q-tab-panels>
              </q-tab-panel>

              <q-tab-panel name="order">
                <q-tabs
                  v-model="dataTab"
                  class="text-teal"
                >
                  <q-tab
                    name="supplyList"
                    :label="$t('Supply')"
                  />

                  <q-tab
                    name="addedServicesList"
                    :label="$t('Added services list')"
                  />
                </q-tabs>
                <q-separator/>
                <q-tab-panels v-model="dataTab" animated>
                  <q-tab-panel name="supplyList">
                    <q-card-section class="q-pa-none">
                      <supplies-table-labelling-order
                        flat
                        :ext-filter="extFilter"
                        :order="order"
                        :services="servicesOrder"
                        @on-dblclick="saveOrder"
                        @changeServices="handleChangeServicesOrder"
                      />
                    </q-card-section>
                  </q-tab-panel>

                  <q-tab-panel name="addedServicesList">
                    <q-card-section class="text-center">
                      <added-supplies-table-order
                        :order="order"
                        :services="servicesOrder"
                        @changeServices="handleChangeServicesOrder"
                      />
                    </q-card-section>
                  </q-tab-panel>
                </q-tab-panels>
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

import AddedSuppliesTable from "@/apps/app/components/tables/AddedSuppliesTable.vue";
import SuppliesTableLabelling from "@/apps/app/components/tables/SuppliesTableLabelling.vue";
import SuppliesTableLabellingOrder from "@/apps/app/components/tables/SuppliesTableLablellingOrder.vue";
import AddedSuppliesTableOrder from "@/apps/app/components/tables/AddedSuppliesTableOrder.vue";

export default {
  name: 'StatusFilter',
    components: {AddedSuppliesTableOrder, SuppliesTableLabellingOrder, SuppliesTableLabelling, AddedSuppliesTable},
  emits: ['on-change'],
  props: {
  },
  data () {
    return {
      isExpanded: false,
      dataTab: 'result',
      isOpen: false,
      extFilter: [],
      data: null,
      currentTab: 'deliveryRequest',
      splitterModel: 20,
      deliveryRequest: null,
      order: null,
      services: [],
      servicesOrder: [],
    }
  },
  computed: {
    width () {
      return '200px'
    }
  },
  watch: {
    isOpen (value) {
      this.isExpanded = value
    }
  },
  methods: {
    save (service) {
      const finalService = { name: service.name, price: service.price, supply: service.id , count: service.quantity}
      return this.$service.deliveryServicesRequestService.save({ ...finalService, deliveryRequest: this.deliveryRequest })
        .then(item => {
          this.services = [item, ...this.services]
        })
    },
    saveOrder (service) {
      const finalService = { name: service.name, price: service.price, supply: service.id , count: service.quantity}
      return this.$service.productsService.save({ ...finalService, order: this.order })
        .then(item => {
          this.services = [item, ...this.services]
        })
    },
    handleExpandCollapse () {
      this.isExpanded = !this.isExpanded
    },
      handleClick (data) {
          this.currentTab = 'deliveryRequest'
          this.data = null

          setTimeout(() => {
              this.data = data
          })
      },
      open (deliveryRequest, order , model = {}) {
        this.isOpen = true
        this.deliveryRequest = deliveryRequest
        this.order = order
        this.model = {
          ...model
        }
      },
      close () {
          this.isOpen = false
      },
      handleChangeServices (services) {
          this.services = services
      },
      handleChangeServicesOrder (services) {
         this.servicesOrder = services
      }
  }
}
</script>

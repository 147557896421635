<template>
  <q-tr :props="data">
    <q-td
        key="number"
        :props="data"
        colspan="4"
    >
      <q-card>
        <div class="row">
          <div class="col-3 q-mt-sm vertical-middle	text-center">
            <img
                :src="data.row.image || fallbackImage"
                style="max-height:100%;max-width:100%;object-fit: contain;"
                @error="onImageLoadFailure"
            >
          </div>
          <div class="col">
            <div class="row">
              <div class="col q-pt-sm" style="text-align: left">
                <string>{{ data.row.name }}</string>
                <p>{{ data.row.id }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col q-pt-sm">
                <span class="row q-pb-sm">
                    {{'Condition: ' + (data.row?.orderProduct?.productOfferRaw?.eav && data.row?.orderProduct?.productOfferRaw?.eav['integrations-amazon-items-condition'] || '-') }}
                </span>
                <span class="row q-pb-sm">
                    {{'Date: ' + $moment((data.row?.orderProduct?.created || {}).date).format(appOptions.formats.date) }}
                </span>
              </div>

              <div class="col q-pt-sm">
                <span class="row q-pb-sm">
                    {{'ASIN: ' + ((data.row?.orderProduct?.productOfferRaw?.eav && data.row?.orderProduct?.productOfferRaw?.eav['integrations-amazon-offer-asin'] || {})[(data.row?.orderProduct?.productOfferRaw?.eav && data.row?.orderProduct?.productOfferRaw?.eav['integrations-amazon-offer-marketplace'] || {})] || '-') }}
                </span>
                <span class="row q-pb-sm">
                    {{'FNSKU: ' + ((data.row?.orderProduct?.eav || {})['integrations-amazon-offer-fnsku'] || '-') }}
                </span>
              </div>
            </div>

            <div class="row" v-if="model?.barcodes && model.barcodes.length > 0">
                <q-checkbox
                    v-model="checkBarcode"
                    :label="labelCheckbox"
                    @click="handleClick"/>
            </div>

          </div>
        </div>
      </q-card>
    </q-td>

    <q-td
        key="total"
        :props="data"
        style="max-width: 60px; word-wrap: break-word; white-space: normal !important; width: 15%"
    >
      <strong>
          {{ 'Total Quantity' }}
      </strong>

      <br>

      <strong style="font-size: 200%">
          {{ data.row.count }}
      </strong>
    </q-td>

    <q-td
        key="actions"
        :props="data"
        style="border-bottom-width: 0 !important;"
        auto-width
    >
      <q-btn
          icon="delete"
          class="q-pb-lg"
          flat
          @click="handleDelete"
      />
    </q-td>
  </q-tr>

</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: 'LabellingMarkNotShippedTable',
  emits: ['remove-item', 'change'],
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null
      }
    },
  },
  data() {
    return {
      quantity: 1,
      invalidInput: false,
      errorMessage: '',
      checkBarcode: false,
      barcodeToChange: '',
      model: {},
      fallbackImage: './assets/img/fallback-image/package.png',
    }
  },
  mounted() {
    this.model = this.data.row
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    labelCheckbox () {
      if( this.model?.barcodes && this.model.barcodes.length > 0) {
        let barcode = this.model.barcodes[0]
        this.barcodeToChange = barcode
        return `Use barcode '${barcode}' for allocation`
      }
      return ''
    },
  },
  methods: {
    handleClick () {
      if( !this.checkBarcode) {
        this.$emit('change', false , this.data.row)
      } else {
        this.$emit('change', true , this.data.row)
      }
    },
    onImageLoadFailure(event) {
      event.target.src = this.appOptions.fallbackImage
    },
    handleDelete() {
      this.$emit('remove-item', this.data.row)
    }
  }
}
</script>
<template>
  <q-card :flat="flat">
    <q-card-section class="row items-center q-pb-md">
      <div class=" col text-h6">
        {{ $t('Services List') }}
      </div>
    </q-card-section>

    <q-card-section class="row items-center full-width q-py-none q-pl-none">

      <search-live
          class="full-width"
          dense
          autoset
          :clear="true"
          :left="true"
          @submit="handleSearch"
      />
    </q-card-section>

    <q-card-section class="q-py-none q-pt-sm">

      <q-tabs
          v-model="tab"
          dense
          class="text-dark"
      >
        <q-tab name="labelling" icon="label" no-caps label="Labelling"
               @click="handleCustomFiltersSubmit('labelling')"/>
        <q-tab name="prep" icon="imagesearch_roller" no-caps label="Prep&Pack"
               @click="handleCustomFiltersSubmit('prep')"/>
        <q-tab name="package" icon="inventory_2" no-caps label="Package" @click="handleCustomFiltersSubmit('package')"/>
        <q-tab name="shipping" icon="airplane_ticket" no-caps label="Label"
               @click="handleCustomFiltersSubmit('shipping')"/>
        <q-tab name="attachment" icon="attach_file" no-caps label="Attachment"
               @click="handleCustomFiltersSubmit('attachment')"/>
        <q-tab name="document" icon="description" no-caps label="Document"
               @click="handleCustomFiltersSubmit('document')"/>
        <q-tab name="custom" icon="crop_landscape" no-caps label="Custom" @click="handleCustomFiltersSubmit('custom')"/>
      </q-tabs>

    </q-card-section>

    <q-card-section class="q-ma-none q-pa-none q-pt-xs">
      <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
              showing
              color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
              :props="props"
              class="clickable"
              style="height: 91px"
          >
            <q-td
                key="number"
                :props="props"
                auto-width
            >
              <div class="text-subtitle1 text-weight-bold">
                {{ props.rowIndex + 1 }}
              </div>
            </q-td>

            <q-td
                key="type"
                :props="props"
                class="text-subtitle1"
            >
              <div v-if="props.row.type">
                <div v-if="props.row.type === 'attachment'">
                  <q-icon
                      name="attach_file"
                      size="md"
                  />
                </div>
                <div v-if="props.row.type === 'package'">
                  <q-icon
                      name="inventory_2"
                      size="md"
                  />
                </div>
                <div v-if="props.row.type === 'shipping'">
                  <q-icon
                      name="airplane_ticket"
                      size="md"
                  />
                </div>
                <div v-if="props.row.type === 'document'">
                  <q-icon
                      name="description"
                      size="md"
                  />
                </div>
                <!--                                <strong>{{ $t(props.row.type) }}</strong>-->
              </div>

              <div v-else>
                <q-icon
                    name="crop_landscape"
                    size="md"
                />
              </div>

            </q-td>

            <q-td
                key="name"
                :props="props"
                style="max-width: 50%;white-space: pre-wrap"
            >
              <div class="text-subtitle1 text-weight-bold" style="text-wrap: normal">
                {{ props.row.name }}
              </div>

              <strong
                  v-if="props.row.type === 'package' && props.row.dimensions"
                  style="text-weight-bold: text-subtitle2"
              >
                {{ `${props.row.dimensions.x}/${props.row.dimensions.y}/${props.row.dimensions.z}` }}
              </strong>
            </q-td>

            <q-td
                key="quantity"
                :props="props"
            >

              <editable-input-field
                  :value="props.row.quantity"
                  :update-function="newValue => handleChange(newValue,props.row , 'quantity')"
                  @change-catch-all="handleCatchAll"
                  :rules="[
                    (val) => /^[0-9]\d*$/.test(val),
                  ]"
              ></editable-input-field>
            </q-td>

            <q-td
                key="label"
                :props="props"
            >

              <q-btn
                  class="q-ml-sm"
                  color="green-6"
                  icon="add"
                  size="sm"
                  no-caps
                  unelevated
                  @click="openModal(props.row)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>
  </q-card>

  <barcode-input-scan-modal ref="barcodeInputModal" @barcodeScan="handleInput"/>

  <service-modal ref="serviceModal" @on-submit="save"/>

  <quantity-modal ref="quantityModal" @submit="onRowDblClick"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import SearchLive from '../../components/search/SearchLive.vue'
import QuantityModal from '../../components/modals/QuantityModal'
import ServiceModal from '@/apps/app/components/modals/ServiceModal.vue'

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Utils
import { buildQuery } from '../../utils/query-utils'
import BarcodeInput from '../../components/barcode-input/BarcodeInput'
import BarcodeInputScanModal from '@/apps/app/components/barcode-input-modal/BarcodeInputScanModal.vue'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'

export default {
  name: 'SuppliesTableLabelling',
  emits: ['change-catch-all', 'change-services'],
  components: {
    EditableInputField,
    BarcodeInputScanModal,
    ServiceModal,
    FilterCollapse,
    SearchLive,
    QuantityModal,
    BarcodeInput
  },
  mixins: [
    TableMixin
  ],
  props: {
    flat: {
      type: Boolean,
      default () {
        return false
      }
    },
    extFilter: {
      type: Array,
      default () {
        return []
      }
    },
    services: {
      type: Array,
      default () {
        return []
      }
    },
    deliveryRequest: {
      type: Number,
    },
  },
  data () {
    return {
      isOpen: false,
      filter: '',
      prevSearch: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      items: [],
      itemsALL: [],
      isLoading: false,
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('+ qty'),
          name: 'quantity',
          align: 'left',
        },
        {
          label: '',
          name: 'label',
          align: 'center'
        }
      ],
      stateColors: {
        active: 'teal',
        blocked: 'grey',
        inactive: 'dark'
      },
      statuses: [
        { id: 'active', title: this.$t('Active') },
        { id: 'inactive', title: this.$t('Inactive') }
      ],
      activatedFields: [
        'id',
        'state',
        'warehouse',
        'price',
        'created.from',
        'created.to'
      ],
      filters: [...this.extFilter, { type: 'eq', field: 'state', value: 'active' }],
      customFilters: '',
      tab: ''
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'isClient',
      'isAdministrator',
      'isSupervisior'
    ]),
    filterBtnText () {
      return this.$t('Filter')
    }
  },
  mounted () {
    if (this.isClient) {
      this.statuses = [{ id: 'active', title: this.$t('Active') }]
    } else if (this.isEmployee) {
      this.statuses = [{ id: 'blocked', title: this.$t('Blocked') }]
    } else if (this.isSupervisior || this.isAdministrator) {
      this.statuses = [
        { id: 'active', title: this.$t('Active') },
        { id: 'blocked', title: this.$t('Blocked') }
      ]
    }

    this.loadDefaultItems()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          per_page: 250,
          forceReload: true
        }
      })
    },
    handleSearch (search) {
      if (search.length > this.prevSearch.length) {
        this.prevSearch = search
        this.items = this.items.filter(e => {
          if (e.name && e.name.toLowerCase().includes(search.toLowerCase())) {
            return e
          }
        })
        this.prevSearch = search
      } else {
        this.prevSearch = search
        let query = {}
        query['order-by'] = [
          { type: 'field', field: 'id', direction: 'desc' }
        ]
        query.filter = [{ type: 'eq', field: 'state', value: 'active' }]
        return this.$service.supply.getAll(query)
            .then(({ items, totalPages, page, totalItems }) => {
              this.pagination = {
                ...this.pagination,
                page,
                rowsNumber: totalItems
              }

              this.items = items.map(e => {
                e.quantity = 1
                return e
              })

              return { items, totalPages, page, totalItems }
            })
      }
    },
    printBarcodes (barcodes) {
      this.$service.printer.generatePDFBarcodes(barcodes.map(barcode => {
        return {
          size: '30x30',
          barcode,
          forceNoAutoClose: true
        }
      }))
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.filters = filter
      return this.onRequest({ pagination: { per_page: 250, page: 1 } })
    },
    handleCustomFiltersSubmit (filter) {
      if (this.customFilters === filter) {
        this.customFilters = ''
        this.tab = ''
        return this.onRequest({ pagination: { per_page: 250, page: 1 } })
      } else {
        this.customFilters = filter
        return this.onRequest({ pagination: { per_page: 250, page: 1 } })
      }
    },
    toggleFilters () {
      this.$emit('change-catch-all', false)
      this.$refs.barcodeInputModal.show()

      // this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: { per_page: 250, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      this.pagination.filter = this.filters

      let query = buildQuery(this.pagination)

      query['order-by'] = [
        { type: 'field', field: 'id', direction: 'desc' }
      ]

      if (!query.filter) {
        query.filter = [{ type: 'eq', field: 'state', value: 'active' }]
      } else {
        query.filter = this.filters
      }

      if (!this.customFilters) {
        this.filters = this.filters.filter(e => e.field !== 'type')
        query.filter = this.filters
      } else if (this.customFilters === 'custom') {
        this.filters = this.filters.filter(e => e.field !== 'type')
        query.filter.push({ type: 'notin', field: 'type', values: ['attachment', 'package', 'shipping', 'document'] })
      } else {
        this.filters = this.filters.filter(e => e.field !== 'type')
        query.filter.push({ type: 'eq', field: 'type', value: this.customFilters })
      }

      this.isLoading = true
      return this.$service.supply.getAll(query)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items.map(e => {
              e.quantity = 1
              return e
            })

            this.itemsAll = items
            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onRowDblClick (row) {
      this.$emit('on-dblclick', row)
      this.$emit('change-catch', true)
    },
    openModal (supply) {
      this.$emit('on-dblclick', supply)
    },
    handleInput (value) {
      const foundSupply = this.items.filter(e => e.barcode === value.raw)
      if (foundSupply.length > 0) {
        foundSupply[0].quantity = 0
        this.$refs.quantityModal.open(foundSupply[0])
      } else {
        this.addErrorNotification(`No supply with id ${value.raw} found`)
      }
    },
    toggle () {
      this.$refs.serviceModal.open()
    },
    save (service) {
      return this.$service.deliveryServicesRequestService.save({ ...service, deliveryRequest: this.deliveryRequest })
          .then(item => {
            const services = [item, ...this.services]
            this.$emit('change-services', services)
          })
    },
    handleChange (value, supply, field) {
      supply.quantity = value
      const index = this.services.findIndex(e => e.id === supply.id)
      if (index >= 0) {
        this.services[index][field] = value
      }
    },
    disableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: false
      })
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    },
    handleCatchAll (value) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })
    }
  }
}
</script>

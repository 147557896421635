<template>
  <div>
    <portal to="assembling-dr">
      <div>
        <span class="text-subtitle2">
         {{ $t('Shipment') + ': ' }}
        </span>

        <span class="text-h6 text-bold">
          {{ data.id }}
        </span>

        <span
          v-if="data.created"
        >
          {{ '/' + $moment(data.created.date).format(appOptions.formats.date) }}
        </span>

        <portal-target name="assembling-dr-place"></portal-target>
      </div>
    </portal>

    <portal
      v-if="data._embedded && data._embedded.deliveryService"
      to="assembling-delivery-service"
    >
      <div>
        <q-img
          v-if="data._embedded.deliveryService && data._embedded.deliveryService.logo"
          :src="appOptions.defaultServer + data._embedded.deliveryService.logo"
          :alt="data._embedded.deliveryService.name"
          :contain="true"
          style="max-width: 200px; height: 45px;"
        />

        <h6 class="q-my-none">
          {{ data._embedded.deliveryService.name }}
        </h6>
      </div>
    </portal>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'DynamicDeliveryRequest',
  props: {
    data: {
      type: Object,
      default () {
        return { id: '' }
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  }
}
</script>

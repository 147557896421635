<template>
  <q-item
    v-ripple
    clickable
    :disable="isLockedDR || isLoaded"
    @dblclick="handleDeliveryRequest"
  >
    <q-item-section
      avatar
      style="width: 100px;"
      class="q-mr-md text-center"
    >
      <div v-if="item._embedded.deliveryService">
        <img
          v-if="item._embedded.deliveryService.logo"
          :src="appOptions.defaultServer + item._embedded.deliveryService.logo"
          :title="item._embedded.deliveryService.name"
          style="width: 100px; height: 20px; object-fit: contain;"
        >

        <h6
          v-else
          class="q-my-none"
          style="overflow-wrap: anywhere"
        >
          {{ item._embedded.deliveryService.name }}
        </h6>
      </div>

      <div v-else>
        {{ $t('No shipping carrier') }}
      </div>
    </q-item-section>

    <q-item-section style="max-width: 160px;">
      <q-item-label>
        {{ $t('ID') + ': ' + item.id }}

        <q-icon
          :name="isLockedDR || isLoaded ? 'lock' : 'lock_open'"
          :color="isLockedDR || isLoaded ? 'negative' : 'positive'"
        />
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        {{ $t('Date') + ': ' + $moment(item.created.data).format(appOptions.formats.date) }}
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        {{ $t('Client Id') + ': ' + (item.clientExtId || item.extId) }}
      </q-item-label>
    </q-item-section>

    <q-item-section style="max-width: 200px;">
      <q-item-label>
        <div v-if="item._embedded.sender">
          {{ item._embedded.sender.name }}
        </div>

        <div v-else>
          {{ $t('No shipping carrier') }}
        </div>
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        <div v-if="item._embedded.rate">
          {{ item._embedded.rate.name }}
        </div>

        <div v-else>
          {{ $t('No shipping rate') }}
        </div>
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        <div v-if="item._embedded.servicePoint">
          {{ item._embedded.servicePoint.name }}
        </div>

        <div v-else>
          {{ $t('No pick up point') }}
        </div>
      </q-item-label>
    </q-item-section>

    <q-item-section style="max-width: 240px;">
      <q-item-label v-if="item._embedded.recipient">
        {{ item._embedded.recipient.name }}
      </q-item-label>

      <q-item-label
        v-if="item._embedded.recipientPhone"
        class="text-caption"
        lines="1"
      >
        {{ item._embedded.recipientPhone.phone }}
      </q-item-label>

      <q-item-label
        v-if="item._embedded.recipientLocality"
        class="text-caption"
        lines="1"
      >
        <span class="q-mr-xs">{{ item._embedded.recipientLocality.postcode }}</span>

        <span class="q-mr-xs">{{ item._embedded.recipientLocality.name }}</span>

        <span class="q-mr-xs">{{ item._embedded.recipientAddress.street }}</span>

        <span class="q-mr-xs">{{ item._embedded.recipientAddress.house }}</span>
      </q-item-label>
    </q-item-section>

    <q-item-section style="max-width: 240px;">
      <q-item-label v-if="item && item.paymentState && paymentStates[item.paymentState]">
        {{ $t('Payment Status') + ': ' + $t(paymentStates[item.paymentState]) }}
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        {{ $t('Payment') + ': ' + item.payment }}
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        {{ $t('Estimated Cost') + ': ' + item.estimatedCost }}
      </q-item-label>

      <q-item-label
        class="text-caption"
        lines="1"
      >
        {{ $t('Retail Cost') + ': ' + (item.retailPrice || 0) }}
      </q-item-label>
    </q-item-section>

    <q-item-section class="text-negative">
      <div
        v-if="item.measureError || item.deliveryServiceStatus"
        class="row items-center"
        style="max-height: 100px; overflow: auto;"
      >
        <q-icon
          name="error"
          size="1.5rem"
        />

        <div class="col q-ml-sm">
          {{ $t(item.measureError || item.deliveryServiceStatus) }}
        </div>
      </div>
    </q-item-section>

    <q-item-section
      side
      class="q-ml-auto"
    >
      <q-btn
        color="light-blue-9"
        text-color="white"
        icon-right="chevron_right"
        :label="$t('Print')"
        :disable="!!disabled"
        no-caps
        @click="handleSend(item)"
      />
    </q-item-section>
  </q-item>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'MeasuringRow',
  emits: ['send', 'dblclick'],
  props: {
    disabled: {
      type: Boolean
    },
    item: {
      type: Object,
      required: true
    },
    deliveryRequest: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      paymentStates: {
        missing: 'Missing',
        payment_waiting: 'Awaiting payment',
        received: 'Received',
        partly_payed: 'Partly paid',
        payed: 'Paid',
        delivery_charged: 'Shipment paid'
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'lockedDeliveryRequests'
    ]),
    isLoaded () {
      return this.deliveryRequest.id === this.item.id
    },
    isLockedDR () {
      return this.lockedDeliveryRequests.includes(this.item.id)
    }
  },
  methods: {
    handleSend (item) {
      this.$emit('send', item)
    },
    handleDeliveryRequest () {
      if (this.isLockedDR || this.isLoaded) {
        return
      }

      this.$emit('dblclick', this.item)
    }
  }
}
</script>

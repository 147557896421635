<template>
  <div class="text-center q-py-sm text-subtitle1">
    {{ $t(data.text) }}
  </div>
</template>

<script>
export default {
  name: 'DesktopMessage',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<template>
  <q-table
    class="sticky-header-table"
    row-key="id"
    :rows-per-page-label="$t('Rows per page')"
    :rows="deliveryRequests"
    :columns="columns"
    v-model:pagination="pagination"
    :loading="deliveryRequestsLoading"
    :filter="filter"
    virtual-scroll
    binary-state-sort
    flat
    @request="onRequest"
  >
    <template v-slot:body="props">
      <delivery-request-row-table
        :data="props"
        :row="props.row"
        :column="props.column"
      />
    </template>
  </q-table>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

// Helpers
import { getStateColors } from '../../helpers/helpers'
import { buildQuery } from '../../utils/query-utils'

// Components
import DeliveryRequestRowTable from '../delivery-request-row-table/DeliveryRequestRowTable'

// Configs
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'

// Mixins
import TableMixin from '../global/TableMixin'

export default {
  name: 'DRList',
  components: {
    DeliveryRequestRowTable
  },
  mixins: [
    TableMixin
  ],
  props: {
    disabledActions: {
      type: Boolean,
      default () {
        return false
      }
    },
    filters: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      stateColors: getStateColors(deliveryRequestStatusMatrix),
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Date'),
          name: 'created',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Sender'),
          name: 'sender',
          align: 'left'
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'deliveryRequests',
      'deliveryRequestsItemsNumber',
      'deliveryRequestsLoading'
    ])
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadDeliveryRequests'
    ]),
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadDeliveryRequests(this.serverParams)
        .then(({ page, totalItems, items }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    loadDefaultItems () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filters: this.filter
      }

      this.onRequest({ pagination })
    }
  }
}
</script>

<template>
  <div class="q-pa-sm">
    <q-table
      row-key="id"
      class="border rounded"
      :rows-per-page-label="$t('Rows per page')"
      :rows="items"
      :columns="columns"
      v-model:pagination="pagination"
      :loading="isLoading"
      :filter="filter"
      @request="onRequest"
      virtual-scroll
      binary-state-sort
      flat
    >
      <template v-slot:loading>
        <q-inner-loading
          showing
          color="primary"
        />
      </template>

      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="clickable"
        >
          <q-td
            key="id"
            :props="props"
            class="text-subtitle1"
          >
            <strong>{{ props.row.id }}</strong>
          </q-td>

          <q-td
            key="order"
            :props="props"
          >
            <div v-if="props.row._embedded.order">
              {{ props.row._embedded.order.id }}
            </div>
          </q-td>

          <q-td
            key="shop"
            :props="props"
          >
            {{ getShopId(props.row) }}
          </q-td>

          <q-td
            key="executive"
            :props="props"
          >
            <div v-if="props.row._embedded.executive">
              {{ props.row._embedded.executive.name }}
            </div>
          </q-td>

          <q-td
            key="state"
            :props="props"
            class="text-subtitle1"
          >
            <q-badge
              :color="stateColor[props.row.state]"
              :label="$t(props.row._embedded.order.state)"
              class="q-px-sm q-py-xs"
            />
          </q-td>

          <q-td
            key="updated"
            :props="props"
          >
            {{ $moment(props.row.updated.date).format(appOptions.formats.fullDate) }}
          </q-td>

          <q-td
            key="label"
            :props="props"
          >
            <q-btn
              color="positive"
              :label="$t('RePrint')"
              @click="handlePrint(props.row)"
              no-caps
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Services
import { InstructionsService } from '../../services/instructions.service'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'DynamicProductTasksTable',
  props: {
    queue: {
      type: [Number, String],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      items: [],
      filter: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      stateColor: {
        new: 'lime-5 text-white',
        confirmed: 'lime-4 text-white',
        complete: 'green text-white',
        rejected: 'red-4 text-dark'
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Store ID'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('User'),
          name: 'executive',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Packed'),
          name: 'updated',
          align: 'left'
        },
        {
          label: this.$t('Label'),
          name: 'label',
          align: 'left'
        }
      ],
      isLoading: false,
      instructionsService: null
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))
    this.onRequest()
  },
  methods: {
    handlePrint (task) {
      const printInstruction = (task.instructions || []).find(x => x.type === 'label-print')

      if (!printInstruction) {
        return
      }

      if (printInstruction.options && printInstruction.options.confirm) {
        printInstruction.options.confirm = undefined
      }

      return this.instructionsService.execute([printInstruction])
    },
    getShopId (task) {
      if (!task._embedded.order) {
        return '--'
      }

      return task._embedded.order._embedded.shop._links.self.href.split('/').pop()
    },
    getOrderID (task) {
      if (task._embedded.order) {
        return task._embedded.order.id
      }

      if (task._embedded.reserve && task._embedded.reserve._embedded && task._embedded.reserve._embedded.order) {
        return task._embedded.reserve._embedded.order._links.self.href.split('/').pop()
      }

      return false
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.page = 1
      query.per_page = 25

      if (!query.filter) {
        query.filter = []
      }

      if (!query['order-by']) {
        query['order-by'] = [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      if (!query.filter.find(x => x.field === 'state')) {
        query.filter.push({ type: 'eq', field: 'state', value: 'complete' })
      }

      if (!query.filter.find(x => x.field === 'queue')) {
        query.filter.push({ type: 'eq', field: 'queue', value: this.queue })
      }

      if (!query.filter.find(x => x.field === 'executive')) {
        const userdata = JSON.parse(localStorage.getItem('userData'))
        query.filter.push({ field: 'executive', type: 'eq', value: userdata.id })
      }

      this.isLoading = true

      return this.$service.assemblingTask.getAll(query)
        .then(({ items, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            rowsNumber: totalItems
          }

          this.items = items
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

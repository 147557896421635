<template>
  <div class="card shadow rounded q-py-md text-subtitle1 text-center">
    <strong>{{ $t('Task') + ': ' + data.id }}</strong>
  </div>
</template>

<script>
export default {
  name: 'DynamicTask',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<template>
  <div class="q-px-md q-mb-md text-center text-h6 text-bold"
       style="min-height: 100%;">
    {{ $t('Bundle')}}
  </div>

  <div v-if="hasError" class="text-negative text-center text-subtitle1">
    {{ $t('Format of bundle is wrong. Please connect with support!')}}
  </div>

  <div v-else class="border rounded">
    <div
      v-for="(item, i) in data"
      :key="item.id"
      class="row justify-center items-stretch"
      :class="`${i < data.length - 1 && data.length > 1 ? 'border-bottom' : ''}`"
    >
      <div class="col q-px-xs row justify-center items-center">
        <div
          class="col col-md-2 col-lg-2 q-py-xs row justify-center items-center"
          @click="handleImageClick(item.productOffer)"
        >
          <img
            style="height: 76px; width: 100%; object-fit: contain;"
            :src="item.productOffer.image || fallbackImage"
            :alt="item.productOffer.name"
            @error="onImageLoadFailure"
          >
        </div>

        <div class="col-5 col-md-4 col-lg-4 text-center">
          {{ item.productOffer.name }} <span>({{ $t('ID') + ': ' + item.productOffer.id }})</span>
        </div>

        <div
          class="col-4 col-md-2 col-lg-3 text-center"
          @dblclick="handleClick(item)"
        >
          {{ $t('UPC') + ': ' + getBarcode(item).join(', ') }}
        </div>

        <div
            class="сщд q-px-md q-my-xs text-center text-h6 border-дуае row items-center text-bold"
            style="min-height: 100%;"
        >
          {{ item.count }}
        </div>
      </div>
    </div>

    <q-dialog
      v-model="isOpenImage"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card
        class="row items-center justify-center"
        style="background: rgba(0, 0, 0, .7);"
        @click="handleImage"
      >
        <img
          style="height: 90vh; width: 90vw; object-fit: contain;"
          :src="image || fallbackImage"
          @error="onImageLoadFailure"
        >
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Components
import newPickingPlace from '../picking/new-picking-place.vue'

export default {
  name: 'DynamicBundlesCollection',
  emits: ['click'],
  components: {
    newPickingPlace
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpenImage: false,
      image: null,
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    hasError () {
      return false
    },
    details () {
      return {
        items: this.data.reduce((acc, item) => {
          return acc + Number(item.count)
        }, 0),
        products: this.data.length
      }
    }
  },
  methods: {
    handleImageClick (offer) {
      this.image = offer.image || this.fallbackImage
      this.isOpenImage = true
    },
    handleImage () {
      this.isOpenImage = false
      this.image = null
    },
    getBarcode (product) {
      return product.productOffer?.barcodes
    },
    handleClick (data) {
      const item = {
        type: this.rawData.type,
        event: this.rawData.event,
        data
      }

      this.$emit('click', item)
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getColor (item) {
      if (item.scannedCount === 0) {
        return 'danger'
      }

      if (item.scannedCount === item.count) {
        return 'green'
      }

      return 'yellow'
    }
  }
}
</script>

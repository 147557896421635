<template>
  <q-dialog
      v-model="isOpen"
      persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section v-if="options && options.message">
        <div class="text-h6 text-center">
          {{ options.message }}
        </div>
      </q-card-section>

      <q-card-section v-if="options && options.quantity > 1">
        <div class="text-h6 text-center">
          {{ scanned }}/{{ scannedCount }} ({{ options.quantity }})
        </div>
      </q-card-section>

      <q-card-section v-else>
        <div class="text-h6 text-center">
          {{ $t('Scan barcode') }}
        </div>
      </q-card-section>

      <q-card-section v-if="isOpen">
        <barcode-input
            :settings="{catchAll: false}"
            :has-max-width="false"
            @barcode="handleBarcode"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import BarcodeInput from '../barcode-input/BarcodeInput'

export default {
  name: 'EntityUpdateModal',
  components: {
    BarcodeInput
  },
  props: {
    manual: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isOpen: false,
      options: {},
      data: {},
      values: [],
      scannedCount: 0,
      currentScannedCount: 0,
    }
  },
  computed: {
    scanned () {
      return this.values.length + (this.scannedCount - this.currentScannedCount)
    }
  },
  methods: {
    ...mapMutations([
      'addNotification',
      'addWarningNotification',
    ]),
    resolve () {},
    reject () {},
    show (data = {}, options = {}) {
      this.data = data
      this.options = options
      this.isOpen = true
      this.scans = []

      if (options.scannedCount) {
        this.scannedCount = options.scannedCount
      }

      if (options.currentScannedCount) {
        this.currentScannedCount = options.currentScannedCount
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    hide () {
      this.isOpen = false
    },
    handleOk () {
      this.resolve(true)
    },
    handleCancel () {
      this.resolve(false)
    },
    handleSkip () {
      this.resolve(true)
    },
    save (url, data) {
      return this.$service.httpClient.post(url, data, 'application/json', 'application/json', '')
          .then(data => {
            this.values = []
            return data
          })
    },
    autoSubmit (data) {
      if (!data && this.values.length === 1) {
        data = this.values[0]
      } else if (this.values.length > 1) {
        return this.submit().then(() => {
          return this.resolve(true)
        })
            .catch(() => {
              return this.resolve(false)
            })
      }

      return this.save(this.options.api, data)
          .then(() => {
            return this.resolve(true)
          })
          .catch(() => {
            return this.resolve(false)
          })
    },
    submit () {
      if (this.values.length <= 0) {
        return Promise.resolve(true)
      }

      return this.save(`${this.options.api}/bulk`, this.values.map(create => ({ create })))
    },
    getValue (barcode) {
      return this.options.base64Encode
          ? window.btoa(barcode.raw)
          : barcode.raw
    },
    handleBarcode (barcode) {
      if (this.options.regex) {
        const regex = new RegExp(this.options.regex)

        if (!barcode?.value.length > 0 || !regex.test(barcode?.value)) {
          this.addWarningNotification('Please, scan data.')

          return this.reject(false)
        }
      }

      const data = {
        ...this.data,
        [this.options.requestField]: this.getValue(barcode)
      }

      this.values.push(data)

      if (this.manual) {
        return this.resolve(true)
      }

      if (!this.options.quantity || this.scannedCount === this.scanned) {
        return this.autoSubmit().then(() => {
          this.hide()
        })
      } else {
        this.addNotification('Scan next value')
      }
    }
  }
}
</script>

<template>
  <q-dialog
      v-model="isOpen"
      persistent
      class="modal-md"
  >
    <q-card class="text-center">
      <q-card-section class="row q-px-xs">
        <div class="col text-h6 no-copy">
          {{ 'How many boxes do you want to clone?' }}
        </div>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="handleClose"
        />
      </q-card-section>

      <q-card-section class="q-pb-none">
        <q-input
            type="number"
            v-model="quantity"
            autofocus
            :rules="[
              (val) => /^\d+$/.test(val) || 'Invalid input. Please enter a number.',
            ]"
            outlined
            input-style="text-align: center; font-size: 2rem"
            @input="handleInput"
        />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
            color="positive"
            :label="$t('Clone')"
            no-caps
            :disable="!quantity"
            @click="save"
        />

        <q-btn
            color="negative"
            :label="$t('Discard')"
            no-caps
            @click="handleClose"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import {mapMutations , mapActions} from "vuex";

export default {
  name: 'CloneBoxesModal',
  emits: ['quantity'],
  components: {
  },
  data () {
    return {
      isOpen: false,
      quantity: 1,
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    ...mapActions([
    ]),
    ...mapMutations([
    ]),
    close () {
      this.isOpen = false
    },
    clone () {

    },
    open (place, deliveryRequest , places) {
      this.isOpen = true

      this.place = place
      this.deliveryRequest = deliveryRequest
      this.places = places
    },
    handleInput (value) {
      this.quantity = value
    },
    handleClose () {
      this.isOpen = false
    },
    save () {
      this.$emit('quantity', {
        place: this.place,
        quantity: this.quantity,
      })

      this.handleClose();
    },
  }
}
</script>

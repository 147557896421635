<template>
  <div>
    <div class="row items-center q-py-sm q-px-md">
      <div class="col row items-center justify-end">
        <portal-target
          v-show="hasQueue"
          name="assembling-queues"
          class="q-ml-sm"
          @change="handleQueueChange"
        />

        <q-btn
          color="warning"
          text-color="dark"
          size="sm"
          :label="$t('Reset')"
          no-caps
          @click="handleReset"
        />

        <portal-target
          v-show="hasQueue"
          name="assembling-buttons"
          class="q-ml-sm"
          @change="handleQueueChange"
        />
      </div>
    </div>

    <portal-target name="assembling-body" />

    <div class="border rounded q-ma-sm">
      <div class="text-dark q-px-sm row items-stretch">
        <div
          class="col text-h6 border-right row items-center q-px-xs"
          style="min-height: 100%;"
        >
          <div>
            {{ $t('Bundle workstation') + ': ' }}
          </div>

          <div v-if="place" class="q-ml-sm">
            {{ place }}
          </div>

          <div
            v-else
            class="col text-center text-white bg-amber rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
          >
            {{ $t('Please scan barcode of your assembly location to start work!') }}
          </div>
        </div>

        <div
          class="col q-px-xs row items-center"
          style="min-height: 72px;"
        >
          <div class="col">
            <barcode-input
              v-if="!isLoading && !isHiddenField"
              :out-focused="isFocused"
              :has-max-width="false"
              :disabled="isLoading"
              @barcode="handleBarcode"
            />
          </div>
        </div>
      </div>

      <div
        v-show="hasOrder"
        class="row items-center border-top q-pa-sm"
      >
        <portal-target
          name="assembling-dr"
          @change="handleDRChange"
        />

        <div v-if="!hasDR" class="text-h6 text-bold">
          {{ $t('No shipment') }}
        </div>

        <q-space />

        <portal-target name="assembling-items" />

        <portal-target name="assembling-actions" />
      </div>

      <div
        v-show="hasOrder"
        class="row items-center border-top q-pa-sm"
      >
        <div class="col-4">
          <portal-target name="assembling-delivery-service" />
        </div>

        <div class="col-4 q-pt-xs">
          <portal-target name="assembling-shop" />
        </div>

        <div class="col-4">
          <portal-target
            name="assembling-order"
            @change="handleOrderChange"
          />
        </div>
      </div>
    </div>

    <div
      v-if="action === 'next'"
      class="q-pa-sm"
    >
      <bundling-content
        :fast-mode="fastMode"
        :barcode="barcode"
        :queues="queues"
        :place="place"
        @start-loading="handleStartLoading"
        @stop-loading="handleStopLoading"
        @block="handleBlock"
        @unblock="handleUnblock"
        @show-barcode-field="showBarcodeField"
      />
    </div>

    <printer-settings-modal
      ref="printerSettingsModal"
      @close="handlePrinterSettingsClose"
    />
    
    <div class="flex wrap justify-center" style="gap:20px" v-if="action === 'scanBasket'">
      <div class="pointer" v-for="place in storagePlaces" :key="place.id">
       <q-td
        style="width:280px;"
      > 
        <new-picking-place
            :data="place"
            :type="place.type"
            @click="handleBarcode(createBarcodeData(place.code))"
            badge
          />
        </q-td>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations,mapActions } from 'vuex'

// Components
import BarcodeInput from '../../../components/barcode-input/BarcodeInput'
import BundlingContent from './BundlingContent'
import PrinterSettingsModal from '../../../components/modals/PrinterSettingsModal.vue'
import NewPickingPlace from '../../../components/picking/new-picking-place.vue'

export default {
  name: 'Bundling',
  components: {
    BarcodeInput,
    BundlingContent,
    PrinterSettingsModal,
    NewPickingPlace
  },
  data () {
    return {
      hasDR: false,
      isFocused: false,
      action: 'scanBasket',
      queues: [],
      place: null,
      barcode: null,
      isLoading: false,
      isHiddenField: false,
      fastMode: true,
      hasOrder: false,
      hasQueue: false,
      storagePlaces: null,
      serverParamsToGetStoragePlaces:{"?per_page":"25","page":"1","order-by":[{"type":"field","field":"created","direction":"desc"}],"filter":[{"type":"in","field":"state","values":["active","normal","blocked"]},{"type":"in","field":"type","values":["assembly"]}]}
    }
  },
  computed: {
    ...mapGetters([
      'printer'
    ]),
  },
  mounted () {
    this.$service.printer.turnOnReconnect()
    this.handleLoadStoragePlaces()
  },
  unmounted () {
    this.$service.printer.turnOffReconnect()

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }
  },
  methods: {
    ...mapActions(['loadStoragePlaces']),
    ...mapMutations([
      'setPrinter',
      'addErrorNotification'
    ]),
    handleLoadStoragePlaces(){
      return this.loadStoragePlaces(this.serverParamsToGetStoragePlaces)
        .then(({ items, totalPages, page, totalItems }) => {
          this.storagePlaces = items
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          return { items, totalPages, page, totalItems }
        })
    },
    createBarcodeData (barcode) {
      const raw = `${barcode}`.trim()
      const expr = /[A-z/]+\/([0-9A-z/]+)/

      if (!raw.match(expr)) {
        return {
          raw,
          value: raw,
          type: ''
        }
      }

      const data = raw
        .split('*')[0]
        .split('/')
        .reduce((acc, value) => {
          if (isNaN(Number(value)) && !acc.value) {
            acc.type += `${value}/`
          } else {
            acc.value += `${value}/`
          }

          return acc
        }, { value: '', type: '', raw })

      data.value = data.value.slice(0, data.value.length - 1)
      data.type = data.type.slice(0, data.type.length - 1)

      return data
    },
    handleDRChange (hasDR) {
      this.hasDR = hasDR
    },
    handleQueueChange (hasQueue) {
      this.hasQueue = hasQueue
    },
    handleOrderChange (hasOrder) {
      this.hasOrder = hasOrder
    },
    handlePrinterSettingsClose () {
      this.isFocused = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.barcode = null
      this.place = null
      this.showBarcodeField()
    },
    openPrinterSettings () {
      this.isFocused = true
      this.$refs.printerSettingsModal.open()
    },
    handleStartLoading () {
      this.isLoading = true
    },
    handleStopLoading () {
      this.isLoading = false
    },
    loadQueues (value) {
      return this.$service.storagePlace.get(value)
        .then(place => {
          this.$channels.user.publish('openPlace', value, place)
          const query = {
            filter: [
              { type: 'eq', field: 'adapter', value: 'bundling' },
              { type: 'eq', field: 'state', value: 'active' }
              // { type: 'eq', field: 'workingPlace', value }
            ]
          }

          return this.$service.assemblingQueue.getAll(query)
            .then(({ items }) => {
              this.isHiddenField = true
              this.place = value
    
              this.queues = items.map(data => {
                return {
                  type: 'Orderadmin\\Storage\\Entity\\Assemblage\\Queue',
                  event: 'storage.assembling.queue.opened',
                  data
                }
              })
    
              this.action = 'next'
            })
        })

    },
    handleBarcode (barcode) {
      if (this.action === 'scanBasket') {
        if (barcode.type !== 'S/P') {
          this.addErrorNotification('Please, scan place. Barcode should start with S/P.')
          return
        }

        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)
          return Promise.resolve(null)
        }

        return this.loadQueues(barcode.value)
      }

      this.barcode = barcode
    },
    showBarcodeField () {
      this.isHiddenField = false
    },
    handleBlock () {
      this.isFocused = true
    },
    handleUnblock () {
      this.isFocused = false
    }
  }
}
</script>

<template>
  <div>
    <div class="border rounded q-ma-sm" v-if="!isPlacesLoaded && !isLoadingFinished">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
        >
          {{ $t('Places are not loaded yet') }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <q-card-section class="q-pt-lg q-mt-sm" v-if="isLoading">
          <q-linear-progress stripe size="10px" class="" ref="progress" :value="progressValue"/>
        </q-card-section>
      </div>

      <div class="col-2 q-pa-md text-right">
        <q-btn
            color="green-6"
            icon="add_box"
            size="md"
            no-caps
            @click="createPlace()"
            :disable="isLoading"
            :label="$t('Add pallet')"
        />
      </div>
    </div>

    <div class="border rounded q-ma-sm" v-if="!isPlacesLoaded && isLoadingFinished">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 text-center text-dark rounded q-pa-sm q-ma-sm"
            style="line-height: 1.2;"
        >
          {{ $t('There is no pallet places in that delivery request') }}
        </div>
      </div>
    </div>

    <div class="q-pa-md" v-if="isPlacesLoaded && isLoadingFinished">
      <div class="row">
        <div class="col-6" v-if="getPoolPlace()">
          <pallet-pool
              :scan-input="scanInput"
              :pool-enabled="poolPlace"
              :pallet="poolPlace"
              :places="currentPlaces"
              :delivery-request="deliveryRequest"
              :place="poolPlace"
              :item-to-move="itemToMove"
              @change-catch-all="handleCatchAll"
              @delete="handleRemove"
              class="no-border"
          />
        </div>

        <div :class="getPoolPlace() ? 'col-6' : 'col-12'">
          <pallet-boxes
              :scan-input="scanInput"
              :pool-enabled="poolPlace"
              :pallet="poolPlace"
              :places="currentPlaces"
              :delivery-request="deliveryRequest"
              :place="poolPlace"
              :item-to-move="itemToMove"
              @change-catch-all="handleCatchAll"
              @delete="handleRemove"
              class="no-border"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { mapGetters, mapMutations } from 'vuex'
import PalletPool from '@/apps/app/components/delivery-requests/PalletPool.vue'
import PalletBoxes from '@/apps/app/components/delivery-requests/PalletBoxes.vue'

export default {
  name: 'DRPallets',
  emits: ['change', 'change-catch-all'],
  components: {
    PalletBoxes,
    PalletPool,
  },
  props: {
    scanInput: {
      type: Object,
      default () {
        return null
      }
    },
    title: {
      type: String,
      default () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    isLoadingFinished: {
      type: Boolean,
      default () {
        return false
      }
    },
  },
  data () {
    return {
      poolPlace: null,
      currentPlaces: [],
      isLoading: false,
      hasChange: false,
      progressValue: 0,
      itemToMove: null
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'deliveryRequest',
    ]),
    total () {
      let orderProducts = []

      return this.currentPlaces.reduce((acc, place) => {
        acc.count += place.items.reduce((acc, item) => {
          if (!orderProducts.includes(item.orderProduct?.id)) {
            orderProducts.push(item.orderProduct?.id)
          }

          return acc + Number(item.count || 0)
        }, 0)

        acc.returned += place.items.reduce((acc, item) => {
          if (item?.orderProduct?.state === 'return') {
            return acc + 1
          }
          return acc
        }, 0)

        acc.shipped += place.items.reduce((acc, item) => {
          if (item?.orderProduct?.state === 'shipped') {
            return acc + 1
          }
          return acc
        }, 0)

        acc.skus = orderProducts.length

        return acc
      }, { count: 0, skus: 0, returned: 0, shipped: 0 })
    },
    isPlacesLoaded () {
      return this.currentPlaces && this.currentPlaces.length > 0
    }
  },
  watch: {
    scanInput (barcode) {
      if (!barcode.type && this.currentPlaces && this.currentPlaces.length > 1) {
        let place = this.currentPlaces[0]
        if (this.poolPlace && this.poolPlace.items) {
          place = this.poolPlace
        }

        const string = `(${barcode.value})`
        const found = place.items.filter(e => e.name.includes(string))
        if (found.length > 0) {
          const itemToMove = found[0]
          this.itemToMove = itemToMove
          this.$refs.labellingMovement.open([itemToMove], place, this.currentPlaces)

          return
        }
      }
    },
    places (value) {
      this.currentPlaces = value
      if (this.currentPlaces.length > 0) {
        this.currentPlaces.sort((a, b) => {
          return a.priority - b.priority
        })
      }

      this.poolPlace = null
    }
  },
  mounted () {
    this.loadPalletPlaces()
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    loadPalletPlaces () {
      const query = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest?.id },
          { type: 'eq', field: 'type', value: 'pallet' }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ],
        'per_page': 250
      }

      return this.$service.deliveryServicePlace.getAll(query)
          .then((result) => {
            this.isLoading = false

            this.currentPlaces = result.items
          })
    },
    createPlace (data) {
      if (data === undefined) {
        data = {
          type: 'pallet',
          deliveryRequest: this.deliveryRequest.id,
          dimensions: null,
          volume: null,
          weight: null
        }
      }

      data.priority = this.currentPlaces.length + 1

      this.isLoading = true
      return this.$service.deliveryServicePlace.save(data).then((updatedPlace) => {
        this.updatePlacesList(updatedPlace)

        return Promise.resolve(updatedPlace)
      }).finally((updatedPlace) => {
        this.isLoading = false

        return Promise.resolve(updatedPlace)
      })
    },
    getPoolPlace () {
      if (this.poolPlace) {
        return this.poolPlace
      }

      let places = this.currentPlaces.filter(e => e.type !== 'pallet' && !e?._embedded?.parent && !e?.type === 'pool')

      if (places.length === 0) {
        return null
      }

      this.poolPlace = places[0]

      return this.poolPlace
    },
    getPlaceTotalSum (place) {
      return place.items.reduce((sum, item) => {
        return sum + ((Number(item.count) || 0) * (Number(item.payment) || 0))
      }, 0)
    },
    updatePlacesList (updatedPlace) {
      const index = this.currentPlaces.findIndex(place => {
        return place.id === updatedPlace.id
      })

      let places = this.currentPlaces
      if (index !== -1 && updatedPlace.state === 'deleted') {
        places.splice(index, 1)
      } else if (index !== -1 && updatedPlace.state !== 'deleted') {
        // If the item exists in the array, replace it with the updated version
        places[index] = updatedPlace
      } else {
        places.unshift(updatedPlace)
      }

      this.currentPlaces = places
    },
    updatePlacesListByItem (updatedItem) {
      let updatedPlace = updatedItem._embedded.place

      this.updatePlacesList(updatedPlace)
    },
    updateItemPlace (place, itemToUpdate) {
      place.items = Object.values(place?.items)

      let index = place.items.findIndex((item) => {
        return item.id === itemToUpdate.id
      })

      if (index !== -1) {
        place.items[index] = itemToUpdate
      }
    },
    removeItemPlace (place, itemToRemove) {
      place.items = Object.values(place.items)

      const index = place.items.findIndex((item) => {
        return item.id === itemToRemove.id
      })

      if (index !== -1) {
        place.items.splice(index, 1)
      }
    },
    convertPlaceToPool (place) {
      if (this.currentPlaces.length === 1) {
        this.isLoading = true
        return this.$service.deliveryServicePlace.save({
          priority: 0,
          type: 'pool',
          eav: { ['delivery-services-request-place-is-pool']: true }
        }, place.id).then(updatedPlace => {
          this.updatePlacesList(updatedPlace)

          this.poolPlace = updatedPlace

          this.isLoading = false
        })
      }

      return Promise.reject(new Error('Several places exists'))
    },
    handleRemove (currentPlace, removed, flag) {
      if (flag) {
        this.isLoading = true
        return this.$service.deliveryServicePlace.save({
          eav: { ['delivery-services-request-place-is-pool']: false },
          type: 'simple'
        }, currentPlace.id).then(updatedPlace => {
          this.updatePlacesList(removed)
          this.updatePlacesList(updatedPlace)

          this.poolPlace = null

          this.isLoading = false
        })
      } else {
        this.updatePlacesList(removed)
        return
      }
    },
    setProgress (progressValue) {
      this.progressValue = progressValue
    },
    disableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: false
      })
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    },
    handleCatchAll (value) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })
    }
  }
}
</script>


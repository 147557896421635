<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card class="text-center">
      <q-card-section class="row q-px-xs">
        <div class="col text-h6 no-copy">
          {{ $t('Choose Quantity') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section>
        <div class="row q-pt-xs">
          <div class="col-4 q-pb-md">
            <q-btn
                flat
                color="info"
                icon="remove"
                class="fit q-btn--no-hover"
                size="lg"
                :disable="value <= 0"
                @click="handleDecrease"
            />
          </div>

          <div class="col-4">
            <q-input
                standout="bg-teal text-white"
                type="number"
                class="q-mx-md full-width text-center q-input--center"
                :model-value="value"
                denses
                @update:model-value="handleAmount"
                @focus="onFocus"
                @blur="onFocusOut"
            />

          </div>

          <div class="col-4 q-pb-md">
            <q-btn
                flat
                color="info"
                icon="add"
                size="lg"
                class="fit q-btn--no-hover"
                @click="handleIncrease"
            />
          </div>

          <div class="col-12 text-center q-pa-sm">
            <q-btn
                no-caps
                unelevated
                style="min-width: 100px;"
                :disable="value <= 0"
                :color="value === 0 ? 'grey' : 'teal'"
                :outline="value === 0"
                :label="$t('OK')"
                @click="handleSubmit"
            />
          </div>
        </div>
      </q-card-section>

    </q-card>

  </q-dialog>
</template>

<script>


export default {
  name: 'QuantityModal',
  emit: ['submit'],
  data () {
    return {
      isOpen: false,
      model: null,
      value: 0
    }
  },
  computed: {},
  methods: {
    close () {
      this.isOpen = false
    },
    open (model = {}) {
      this.model = model
      this.value = this.model.quantity ?? 1
      this.isOpen = true
    },
    handleDecrease () {
      this.value -= 1
    },
    handleIncrease () {
      this.value += 1
    },
    handleAmount (value) {
      this.value = value
    },
    handleSubmit () {
      this.model.quantity = this.value
      this.$emit('submit', this.model)
      this.value = 0
      this.close()
    },
    onFocus () {
      this.$emit('click', { event: 'counter.focus' })
    },
    onFocusOut () {
      this.$emit('click', { event: 'counter.focusOut' })
    }
  }
}
</script>
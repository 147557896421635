<template>
  <div>
    <portal to="assembling-order">
      <div>
        <div class="text-subtitle2">
          {{ $t('Order') + ':' }}
        </div>

        <div class="text-h6 text-bold bg-teal-5" v-if="rawData?.options?.id === 0">
          {{ $t('MULTIPLE') }}
        </div>

        <div class="text-h6 text-bold" v-else>
          {{ data.id }}
        </div>
      </div>
    </portal>

    <portal
        v-if="data._embedded"
        to="assembling-shop"
    >
      <div>
        <div class="text-subtitle2">
          {{ $t('Store') + ':' }}
        </div>

        <div
            v-if="data._embedded.shop"
            class="text-h6 text-bold"
        >
          {{ data._embedded.shop.name }}
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'DynamicOrder',
  props: {
    data: {
      type: Object,
      default () {
        return { id: '' }
      }
    },
    rawData: {
      type: Object,
      default () {
        return { options: { id: null } }
      }
    }
  }
}
</script>

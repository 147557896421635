<template>
  <form
      :class="dense ? '' : 'q-pa-sm'"
  >
    <q-input
        ref="field"
        v-model="search"
        :standout="dark ? 'bg-dark text-white' : 'bg-light-blue-9 text-white'"
        :label="!placeholder ? $t('Search') : ''"
        :class="`expandable-field ${className}`"
        :dense="dense"
        :rounded="rounded"
        :outlined="outlined"
        :placeholder="placeholder"
        @click="onFocus"
        @blur="onFocusOut"
        @keyup="handleSubmit(search)"
    >
      <template v-if="left" v-slot:prepend>
        <q-icon
          name="search"
          class="cursor-pointer"
          @click="onFocus"
        />
      </template>

      <template v-slot:append>
        <q-btn
            v-if="clear && search !== ''"
            flat
            no-caps
            dense
            class="cursor-pointer"
            label="clear"
            @click="handleClear"
        />

        <q-icon
            v-if="isExpandable && isFocused && search !== ''"
            name="clear"
            class="cursor-pointer"
            @click="handleClear"
        />
      </template>

      <template v-if="!left" v-slot:after>
        <q-icon
            name="search"
            class="cursor-pointer"
            @click="onFocus"
        />
      </template>
    </q-input>
  </form>
</template>

<script>
export default {
  name: 'SearchLive',
  emits: ['submit', 'change-catch-all'],
  props: {
    defaultSearch: {
      type: String,
      default () {
        return ''
      }
    },
    placeholder: {
      type: String
    },
    autoset: {
      type: Boolean,
      default () {
        return false
      }
    },
    dark: {
      type: Boolean,
      default () {
        return false
      }
    },
    isExpandable: {
      type: Boolean,
      default () {
        return false
      }
    },
    dense: {
      type: Boolean,
      default () {
        return false
      }
    },
    outlined: {
      type: Boolean,
      default () {
          return false
      }
    },
    rounded: {
      type: Boolean,
      default () {
          return false
      }
    },
    left: {
        type: Boolean,
        default () {
            return false
        }
    },
    clear: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      search: this.defaultSearch,
      isFocused: false
    }
  },
  computed: {
    className () {
      if (!this.isExpandable) {
        return ''
      }

      return this.isFocused || this.search.length > 0
          ? 'expandable-field--expand'
          : 'expandable-field--collapse'
    }
  },
  mounted () {
    if (this.autoset) {
      this.search = this.$route.query.search || ''

      if (this.search.endsWith(':*')) {
        this.search = this.search.slice(0, this.search.length - 2)
      }
    }
  },
  methods: {
    onFocus () {

      this.isFocused = true
      setTimeout(() => {
        this.$emit('change-catch-all', false)
      }, 10)
    },
    onFocusOut () {

      this.isFocused = false
      this.$emit('change-catch-all', true)
    },
    handleClear () {
      this.$emit('submit', '')
      this.search = ''
    },
    handleSubmit (e) {
        this.search = e
        this.$emit('submit', this.search)

    }
  }
}
</script>

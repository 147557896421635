<template>
  <q-card :flat="flat">
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <search-live
          dense
          autoset
          is-expandable
          @submit="handleSearch"
      />

      <q-space />

      <q-btn
        :color="filters && filters.length > 0 ? 'light-blue-9' : 'dark'"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="filterBtnText"
        no-caps
        unelevated
        @click="toggleFilters"
      />

      <q-btn
        color="dark"
        text-color="white"
        :label="$t('Refresh')"
        class="q-mr-sm"
        size="sm"
        no-caps
        unelevated
        @click="refreshItems"
      />
    </q-card-section>

    <filter-collapse
      :is-open="isOpen"
      :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true
        }
      }"
      @submit="handleFiltersSubmit"
      @close="toggleFilters"
    />

    <q-card-section>
      <barcode-input
          :settings="{catchAll: false}"
          :out-focused="false"
          :has-max-width="false"
          :disabled="isLoading"
          @barcode="handleInput"
      />
    </q-card-section>

    <q-card-section class="q-ma-none q-pa-none">
      <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
            @dblclick="openModal(props.row)"
          >
            <q-td
              key="type"
              :props="props"
              class="text-subtitle1"
            >
              <div v-if="props.row.type">
                <strong>{{ $t(props.row.type) }}</strong>
              </div>

            </q-td>

            <q-td
              key="name"
              :props="props"
            >
              <div class="text-subtitle1 text-weight-bold">
                {{ props.row.name }}
              </div>

              <strong
                v-if="props.row.type === 'package' && props.row.dimensions"
                style="text-weight-bold text-subtitle2"
              >
                {{ `${props.row.dimensions.x}/${props.row.dimensions.y}/${props.row.dimensions.z}` }}
              </strong>
            </q-td>


            <q-td
              key="price"
              :props="props"
            >
              {{ props.row.price }}
            </q-td>

            <q-td
              key="label"
              :props="props"
            >
              <q-btn
                v-if="props.row.barcode"
                color="dark"
                text-color="white"
                :label="$t('Print')"
                size="sm"
                no-caps
                unelevated
                @click="printBarcodes([props.row.barcode])"
              />

              <q-btn
                class="q-ml-sm"
                color="green-6"
                :label="$t('Add')"
                size="sm"
                no-caps
                unelevated
                @click="openModal(props.row)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
  <quantity-modal ref="quantityModal" @submit="onRowDblClick"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import SearchLive from '../../components/search/SearchLive.vue'
import QuantityModal from "../../components/modals/QuantityModal";

// Mixins
import TableMixin from './../../components/global/TableMixin'

// Utils
import { buildQuery } from '../../utils/query-utils'
import BarcodeInput from "../../components/barcode-input/BarcodeInput";


export default {
  name: 'SuppliesTable',
  components: {
    FilterCollapse,
    SearchLive,
    QuantityModal,
    BarcodeInput
  },
  mixins: [
    TableMixin
  ],
  props: {
    flat: {
      type: Boolean,
      default () {
        return false
      }
    },
    extFilter: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isOpen: false,
      filter: '',
      prevSearch: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      items: [],
      itemsALL: [],
      isLoading: false,
      columns: [
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left',
          sortable: true
        },
        {
          label: '',
          name: 'label',
          align: 'center'
        }
      ],
      stateColors: {
        active: 'teal',
        blocked: 'grey',
        inactive: 'dark'
      },
      statuses: [
        { id: 'active', title: this.$t('Active') },
        { id: 'inactive', title: this.$t('Inactive') }
      ],
      activatedFields: [
        'id',
        'state',
        'warehouse',
        'price',
        'created.from',
        'created.to'
      ],
      filters: [...this.extFilter, { type: 'eq', field: 'state', value: 'active' }]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'isClient',
      'isAdministrator',
      'isSupervisior'
    ]),
    filterBtnText () {
      return this.$t('Filter')
    }
  },
  mounted () {
    if (this.isClient) {
      this.statuses = [{ id: 'active', title: this.$t('Active') }]
    } else if (this.isEmployee) {
      this.statuses = [{ id: 'blocked', title: this.$t('Blocked') }]
    } else if (this.isSupervisior || this.isAdministrator) {
      this.statuses = [
        { id: 'active', title: this.$t('Active') },
        { id: 'blocked', title: this.$t('Blocked') }
      ]
    }

    this.loadDefaultItems()
  },
  methods: {
    ...mapMutations([
        'addErrorNotification'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleSearch (search) {
      if (search.length > this.prevSearch.length) {
        this.prevSearch = search
        this.items = this.items.filter(e=> {
          if (e.name && e.name.toLowerCase().includes(search.toLowerCase())) {
            return e
          }
        })
        this.prevSearch = search
      } else {
        this.prevSearch = search
        let query = {}
        query['order-by'] = [
          {type: 'field', field: 'id', direction: 'desc'}
        ]
        query.filter = [{type: 'eq', field: 'state', value: 'active'}]
        return this.$service.supply.getAll(query)
            .then(({ items, totalPages, page, totalItems }) => {
              this.pagination = {
                ...this.pagination,
                page,
                rowsNumber: totalItems
              }

              this.items = items
              return { items, totalPages, page, totalItems }
            })
      }
    },
    printBarcodes (barcodes) {
      this.$service.printer.generatePDFBarcodes(barcodes.map(barcode => {
        return {
          size: '30x30',
          barcode,
          forceNoAutoClose: true
        }
      }))
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      this.pagination.filter = this.filters

      let query = buildQuery(this.pagination)

      query['order-by'] = [
        { type: 'field', field: 'id', direction: 'desc' }
      ]
      query.filter=[{ type: 'eq', field: 'state', value: 'active' }]
      this.isLoading = true
      return this.$service.supply.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items
          this.itemsAll = items
          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onRowDblClick (row) {
      this.$emit('on-dblclick', row)
    },
    openModal (supply) {
      supply.quantity = 0
      this.$refs.quantityModal.open(supply)
    },
    handleInput (value) {
      const foundSupply = this.items.filter(e => e.barcode === value.raw)
      if( foundSupply.length >0) {
        foundSupply[0].quantity = 0
        this.$refs.quantityModal.open(foundSupply[0])
      } else {
        this.addErrorNotification(`No supply with id ${value.raw} found`)
      }
    },
  }
}
</script>

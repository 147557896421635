<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t('Scan barcode') }}
        </div>

      </q-card-section>

      <q-card-section>
        <barcode-input
          :settings="{catchAll: false}"
          :has-max-width="false"
          @barcode="handleBarcode"
        />
      </q-card-section>


      <q-card-section
        class="text-center"
      >
        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Close')"
          @click="hide"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import BarcodeInput from '../barcode-input/BarcodeInput'

export default {
  name: 'BarcodeInputScanModal',
  emits: ['barcode-scan'],
  components: {
      BarcodeInput
  },
  data () {
    return {
      isOpen: false,

    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'addNotification'
    ]),
    show () {
      this.isOpen = true
    },
    hide () {
      this.isOpen = false
    },
    handleBarcode (barcode) {
      this.isOpen = false
      this.$emit('barcode-scan', barcode)
    }
  }
}
</script>

<template>
  <div>
    <div
      v-for="(item, i) in data.objects"
      :key="i"
      class="q-mb-sm"
    >
      <component
        :is="types[item.type]"
        v-bind="{ data: item.data, rawData: item }"
        @click="handleClick"
      />
    </div>

    <div
      class="fixed-bottom-right text-right"
      style="bottom: 10px; right: 20px;"
    >
      <q-btn
        v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
        class="q-mb-sm block q-ml-auto fab"
        fab
        size="lg"
        icon-right="print"
        color="light-blue-9"
        :label="$t('Print')"
        label-position="left"
        dark
        @click="handleClick({ type: 'storage.queue.task.print' })"
      />

      <q-btn
        v-if="queue"
        class="q-mb-sm block q-ml-auto fab"
        fab
        size="lg"
        icon-right="assignment_late"
        color="negative"
        :label="$t('Rejected tasks')"
        label-position="left"
        dark
        @click="handleOpenTasks"
      />

      <q-btn
        v-if="dataObjects['request-data']"
        fab
        class="q-mb-sm block q-ml-auto fab"
        size="lg"
        icon-right="archive"
        color="primary"
        :label="$t((dataObjects['request-data'] && dataObjects['request-data'].options && dataObjects['request-data'].options.label) || 'Box')"
        label-position="left"
        dark
        @click="handleOpenRequestData"
      />

      <q-btn
        v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
        fab
        class="q-mb-sm block q-ml-auto fab"
        size="lg"
        icon-right="skip_next"
        color="dark"
        :label="$t('Skip task')"
        label-position="left"
        dark
        @click="handleClick({ type: 'storage.queue.task.reject' })"
      />
    </div>

    <q-dialog v-model="amountModal">
      <q-card style="min-width: 50vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6 q-my-none">
            {{ $t('Amount') }}
          </div>

          <q-space />

          <q-btn
            icon="close"
            flat
            round
            dense
            @click="handleCloseAmount"
          />
        </q-card-section>

        <q-card-section class="row justify-center items-center">
          <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            :label="$t('Max') + ': ' + maxAmount"
            @click="handleAddAll"
          />

          <div
            class="col row items-center q-px-sm justify-center"
            style="max-width: 350px;"
          >
            <q-btn
              color="negative"
              text-color="white"
              size="sm"
              icon="remove"
              :disable="count <= 1 || isLoading"
              @click="handleDecrease"
            />

            <div
              class="col q-px-sm"
              style="min-width: 80px; max-width: 250px;"
            >
              <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="count"
                :label="$t('Amount')"
                :disable="isLoading"
                dense
                @update:model-value="handleAmount"
                @focus="onFocus"
                @blur="onFocusOut"
              />
            </div>

            <q-btn
              color="positive"
              text-color="white"
              size="sm"
              icon="add"
              :disable="isLoading || count >= maxAmount"
              @click="handleIncrease"
            />
          </div>

          <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            :label="$t('Submit')"
            @click="handleItemClick({ data: product })"
          />
        </q-card-section>

        <q-card-section class="q-my-sm">
          <dynamic-products-collection :data="[product]" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <barcode-input-modal ref="barcodeInputModal" />

    <delivery-request-places-modal ref="drPlacesModal" />

    <confirm-modal ref="confirmModal" />

    <measuring-fast-modal ref="measuringFastModal" />

    <entity-update-modal ref="entityUpdateModal" :manual="fastMode" />

    <request-data-modal
      ref="requestDataModal"
      @submit="handleRequestData"
      @close="handleRequestDataClose"
    />

    <assembling-tasks-modal ref="assemblingTasksModal" />

    <portal v-if="!!queue" to="assembling-queues">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('All queues')"
        no-caps
        @click="handleClick({ type: 'storage.queue.choose' })"
      />
    </portal>

    <portal v-if="!!queue" to="assembling-buttons">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        :icon-right="isOpenQueue ? 'expand_less' : 'expand_more'"
        :label="$t('Packed')"
        no-caps
        @click="handleQueueOpenClose"
      />
    </portal>

    <portal v-if="!!queue" to="assembling-body">
      <q-slide-transition>
        <dynamic-product-tasks-table
          v-if="isOpenQueue"
          :queue="queue"
        />
      </q-slide-transition>
    </portal>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Services
import { InstructionsService } from '../../../services/instructions.service'

// Components
import DesktopMessage from './../../../components/dynamic-components/desktop-message'
import DynamicCollection from './../../../components/dynamic-components/dynamic-collection'
import DynamicOrder from './../../../components/dynamic-components/dynamic-order'
import PickingItem from './../../../components/picking/picking-item'
import NewPickingPlace from './../../../components/picking/new-picking-place'
import PickingQueue from './../../../components/picking/picking-queue'
import BarcodeInputModal from '../../../components/barcode-input-modal/BarcodeInputModal'
import DynamicTask from '../../../components/dynamic-components/dynamic-task'
import DeliveryRequestPlacesModal from '../../../components/modals/DeliveryRequestPlacesModal.vue'
import DynamicProductsCollection from '../../../components/dynamic-components/dynamic-products-collection.vue'
import ConfirmModal from '../../../components/confirm-modal/ConfirmModal.vue'
import RequestDataModal from '../../../components/modals/RequestDataModal.vue'
import DynamicDeliveryRequest from '../../../components/dynamic-components/dynamic-delivery-request.vue'
import AssemblingTasksModal from '../../../components/modals/AssemblingTasksModal.vue'
import DynamicProductTasksTable from '../../../components/dynamic-components/dynamic-product-tasks-table.vue'
import EntityUpdateModal from '../../../components/modals/EntityUpdateModal.vue'
import MeasuringFastModal from '../../../components/modals/MeasuringFastModal.vue'

export default {
  name: 'BundlingContent',
  emits: ['stop-loading', 'start-loading', 'fast-mode', 'show-barcode-field', 'block', 'unblock'],
  components: {
    PickingQueue,
    PickingItem,
    DesktopMessage,
    NewPickingPlace,
    DynamicCollection,
    BarcodeInputModal,
    DynamicOrder,
    DynamicTask,
    DeliveryRequestPlacesModal,
    DynamicProductsCollection,
    ConfirmModal,
    RequestDataModal,
    DynamicDeliveryRequest,
    AssemblingTasksModal,
    DynamicProductTasksTable,
    EntityUpdateModal,
    MeasuringFastModal
  },
  props: {
    fastMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    barcode: {
      type: Object,
      default () {
        return {}
      }
    },
    queues: {
      type: Array,
      default () {
        return []
      }
    },
    place: {
      type: [String, Number],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isOpenQueue: false,
      isLoading: false,
      types: {
        message: 'desktop-message',
        'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest': 'dynamic-delivery-request',
        // 'Orderadmin\\Storage\\Entity\\Assemblage\\Task': 'dynamic-task',
        'Orderadmin\\Storage\\Entity\\AbstractPlace': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Row': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Section': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Employee': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Sorting': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Distribution': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Assembly': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Universal': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Pallet': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Defected': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Room': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Item': 'picking-item',
        'Orderadmin\\Storage\\Entity\\Assemblage\\Queue': 'picking-queue',
        collection: 'dynamic-collection',
        'Orderadmin\\Products\\Entity\\Order': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\ReturnOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\RetailOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder': 'dynamic-order',
      },
      amountModal: false,
      maxAmount: 1,
      count: 1,
      queue: null,
      adapter: null,
      product: null,
      dataObjects: {},
      data: {
        place: this.place,
        objects: [
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          },
          ...this.queues
        ]
      },
      instructionsService: null,
      scannedBarcodes: []
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'settings'
    ])
  },
  watch: {
    barcode (newVal) {
      if (this.data.event === 'storage.assembling.scan.item') {
        this.handleProductScan(newVal)
        return
      }

      this.handleBarcode(newVal)
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleQueueOpenClose () {
      this.isOpenQueue = !this.isOpenQueue
    },
    handleCloseAmount () {
      this.count = 1
      this.amountModal = false
    },
    handleProductScan (barcode) {
      return this.updateCollection(barcode)
        .then(({ products, scannedCount, count }) => {
          if (products.length <= 0) {
            this.addErrorNotification(`Product with barcode ${barcode.raw} is not found or maximum quantity is scanned!`)
            return
          }

          this.scannedBarcodes = [
            ...products,
            ...this.scannedBarcodes
          ]

          this.product = products[0].raw

          return this.executeInstructions(this.product.instructions)
            .then(() => {
              if (scannedCount >= count) {
                return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
                  .then(isOk => {
                    if (!isOk) {
                      this.revertChanges(this.product, products[0].mainTask)
                    }

                    return isOk
                  })
              }

              if (this.fastMode) {
                return false
              }

              return true
            })
            .then(isOk => {
              if (isOk) {
                return this.handleSubmit(this.scannedBarcodes)
              }

              return isOk
            })
            .then(isOk => {
              if (this.product.scannedCount < this.product._embedded.orderProduct.count) {
                this.maxAmount = this.product._embedded.orderProduct.count - this.product.scannedCount
                this.amountModal = true
              }

              return isOk
            })
            .finally(() => {
              this.count = 1
            })
        })
    },
    revertChanges (product, mainProduct) {
      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask)

      this.dataObjects = {
        ...this.dataObjects,
        [collectionTaskName]: {
          ...this.dataObjects[collectionTaskName],
          data: this.dataObjects[collectionTaskName].data.map(p => {
            if (p.id == mainProduct.id) {
              const updatedProduct = { ...p }

              if (updatedProduct.scannedCount >= updatedProduct.count) {
                updatedProduct.scannedCount -= 1
                const index = this.scannedBarcodes.findIndex(x => x.id == updatedProduct.id)
                this.scannedBarcodes = this.scannedBarcodes.filter((_, i) => i !== index)
              }

              updatedProduct.childTasks = updatedProduct.childTasks.map(child => {
                if (child.id == product.id) {
                  const index = this.scannedBarcodes.findIndex(x => x.id == child.id)
                  this.scannedBarcodes = this.scannedBarcodes.filter((_, i) => i !== index)
                  return { ...child, scannedCount: product.scannedCount - this.count }
                }

                return child
              })

              return updatedProduct
            }

            return p
          })
        }
      }

      this.data = {
        ...this.data,
        objects: this.data.objects.map(x => {
          return x.type === 'collection' && x.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask
            ? this.dataObjects[collectionTaskName]
            : x
        })
      }

      return product
    },
    updateCollection (barcode) {
      
      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask)
      let isIncreased = false
      let products = []

      let scannedCount = 0
      let count = 0
      this.dataObjects = {
        ...this.dataObjects,
        [collectionTaskName]: {
          ...this.dataObjects[collectionTaskName],
          data: this.dataObjects[collectionTaskName].data.map(task => {
            let isIncreasedNow = false

            if(task.childTasks){
              task.childTasks = task.childTasks.map(childTask => {
                  const barcodes = this.getBarcode(childTask)

                  if (barcodes.find(value => `${value}`.trim() === barcode.raw) && !isIncreased) {
                    const scannedCount = childTask.scannedCount + this.count

                    if (scannedCount > childTask.count) {
                      this.addErrorNotification('You already scan maximum quantity!')
                    } else {
                      isIncreased = true
                      isIncreasedNow = true
                      childTask.scannedCount = scannedCount
                      products.push({ id: childTask.id, quantity: scannedCount, timestamp: new Date().toISOString(), raw: childTask, type: 'child', mainTask: task })
                    }
                  }

                  return childTask
                })
            }
              
            if (task.childTasks && !task.childTasks.find(task => task.scannedCount < task.count)) {
                task.scannedCount += 1
                products.push({ id: task.id, quantity: 1, timestamp: new Date().toISOString(), type: 'main', raw: task })

                // if (task.scannedCount < task.count) {
                //   task.childTasks = task.childTasks.map(child => {
                //     child.scannedCount = 0
                //     return child
                //   })
                // }
            }else {
              // task.scannedCount += 1
                products.push({ id: task.id, quantity: 1, timestamp: new Date().toISOString(), type: 'main', raw: task })
            }

            scannedCount += task.scannedCount
            count += task._embedded.orderProduct.count
            return task
          })
        }
      }

      this.data = {
        ...this.data,
        objects: this.data.objects.map(x => {
          return x.type === 'collection' && x.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask
            ? this.dataObjects[collectionTaskName]
            : x
        })
      }

      return Promise.resolve({ products, scannedCount, count })
    },
    handleOpenTasks () {
      const filter = [
        { field: 'queue', type: 'eq', value: this.queue },
        { field: 'state', type: 'eq', value: 'rejected' }
      ]

      this.$refs.assemblingTasksModal.open(filter, this.$t('Rejected Tasks'))
    },
    handleRequestDataClose () {
      this.$emit('stop-loading')
    },
    handleRequestData (data) {
      this.$refs.requestDataModal.close()

      this.data = {
        ...this.data,
        ...data
      }

      return this.handleSubmit(this.scannedBarcodes)
    },
    forceExecuteScanning () {
      return Promise.resolve(this.scannedBarcodes.length > 0)
        .then(hasBarcodes => {
          return hasBarcodes
            ? this.handleSubmit(this.scannedBarcodes)
            : Promise.resolve([])
        })
    },
    handleOpenRequestData () {
      this.$refs.requestDataModal.open(this.dataObjects['request-data'])
      this.$emit('start-loading')
    },
    handleAddAll () {
      this.count = this.maxAmount
      this.handleItemClick({ data: this.product })
    },
    getBarcode (product) {
      return (product.instructions.find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    executeInstructions (instructions) {
      this.$emit('start-loading')

      const reducedInstructions = instructions.reduce((acc, instruction) => {
        if (Array.isArray(instruction)) {
          acc = [...acc, ...instruction]
        } else {
          acc.push(instruction)
        }

        return acc
      }, [])

      return this.instructionsService.execute(reducedInstructions)
        .then(result => {
          this.$emit('stop-loading')
          return result
        })
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    handleBarcode (barcode) {
      if (this.data.requiredData && this.data.requiredDataType) {
        const validations = {
          regexp: (value, validation) => {
            const regex = new RegExp(validation)
            return !regex.test(value.raw)
          },
          text: (value, validation) => {
            return value.raw !== validation
          }
        }

        if (!validations[this.data.requiredDataType]) {
          return this.addErrorNotification('Validation is not recognized!')
        }

        if (validations[this.data.requiredDataType](barcode, this.data.requiredData)) {
          return this.addErrorNotification('Barcode does not match scheme!')
        }
      }

      return this.handleSubmit(barcode)
    },
    handleSubmit (value) {
      let productsIsScanned = true

      if(this.dataObjects[`collection:${this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask}`]){
        const bundls = this.dataObjects[`collection:${this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask}`].data
        bundls.forEach(bundl =>{
          bundl.childTasks.forEach(child => {
            if (!(child.count == child.scannedCount)){
              productsIsScanned = false  
            } 
          })
        })
      }

      if(!productsIsScanned){
        return
      }

      // const entity = value && Array.isArray(value)
      //   ? value.reduce((acc, item) => {
      //     if (item.type === 'main') {
      //       acc.push({ id: item.id, quantity: item.quantity, timestamp: item.timestamp })
      //     }
      //     return acc
      //   }, [])
      //   : value

      let entity
      if(value && value[0] && value[0].mainTask){
         entity = value[0].mainTask.id
      }else {
         entity = value
      }
      
      const data = this.reduceData(
        [
          'objects',
          'queue',
          'requiredData',
          'requiredDataType',
          'entity',
          'timestamp'
        ],
        {
          timestamp: new Date().toISOString(),
          entity,
          place: this.place
        }
      )

      if (entity && typeof entity === 'object' && entity.raw) {
        data.entity = entity.raw
      }

      const task = this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]

      if (task) {
        data.task = task.data.id
      }

      const supplyPlace = this.dataObjects[this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest_Place]

      if (supplyPlace) {
        data.supplyPlace = supplyPlace.data.id
      }

      this.isLoading = true
      this.$emit('start-loading')

      return this.$refs.entityUpdateModal.submit()
        .then(() => {
          return this.$service.assemblingQueue.process(this.queue, data, undefined, 'application/json+event')
        })
        .then(data => {
          this.data = data 
          this.data.objects = this.data.objects.map(obj => {
            return obj.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask
              ? this.createBundleCollection(obj)
              : obj
          })

          

          this.scannedBarcodes = []
          this.dataObjects = data.objects.reduce((acc, obj) => {
            const key = obj.type === 'collection'
              ? this.getCollectionName(obj.entityClass)
              : obj.type

            acc[key] = obj

            return acc
          }, {})

          if (this.dataObjects.instruction && !this.dataObjects.instructions) {
            const instructions = this.dataObjects.instruction.data.find(x => x.type === 'instructions')

            if (instructions) {
              this.dataObjects.instructions = instructions
            }
          }

          if (this.dataObjects.instructions && this.dataObjects.instructions.options && this.dataObjects.instructions.options.run === 'immediately') {
            this.executeInstructions(this.dataObjects.instructions.data)
          }
        })
        .catch(() => {
          if (this.data.event === 'storage.assembling.scan.item') {
            this.revertChanges(this.product, this.scannedBarcodes[0].mainTask)

            if (!this.fastMode) {
              this.scannedBarcodes = []
            }
          }
        })
        .finally(() => {
          this.$emit('stop-loading')
          this.count = 1
          this.isLoading = false
        })
    },
    createBundleCollection (taskCollection) {
      return {
        type: 'collection',
        entityClass: this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductBundleTask,
        data: this.tranformOrderProductsTasks(taskCollection.data)
      }
    },
    tranformOrderProductsTasks (tasks) {
      const separatedTasks = tasks.reduce((acc, task) => {
        if (task._embedded.orderProduct.productOfferRaw.type === 'bundle') {
          acc.bundles.push({ ...task, count: task._embedded.orderProduct.count, childTasks: task._embedded.orderProduct.productOfferRaw.eav['products-offer-bundle'] })
        } else {
          acc.others.push(task)
        }

        return acc
      }, { bundles: [], others: [] })

      if(separatedTasks.bundles.length == 0){
        return separatedTasks.others
      }

      separatedTasks.bundles.forEach(task => {
        task.childTasks = task.childTasks.map(offer => {
          const childTask = separatedTasks.others.find(task => task._embedded.orderProduct.productOfferRaw.id == offer.productOffer)

          return {
            ...childTask,
            scannedCount: 0,
            count: Number(offer.count)
          }
        })
      })

      return separatedTasks.bundles
    },
    handleItemClick (item) {
      this.amountModal = false
      const barcodes = this.getBarcode(item.data)

      if (!barcodes || barcodes.length <= 0) {
        this.addErrorNotification(`No barcodes in that product! Barcode value ${JSON.stringify(barcodes)}`)
        return
      }

      const barcode = {
        value: barcodes[0],
        raw: barcodes[0]
      }

      return this.handleProductScan(barcode)
    },
    handleQueueClick (item) {
      this.queue = item.data.id
      this.adapter = item.data.adapter
      this.data.entityClass = item.type
      this.data.event = item.event

      const fastMode = item.data.settings && item.data.settings['fast-mode'] !== undefined
        ? !!item.data.settings['fast-mode']
        : true

      this.$emit('fast-mode', fastMode)

      return this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
        .then(() => {
          this.$emit('show-barcode-field')
        })
    },
    handleClick (item) {
      const actionsByType = {
        'storage.queue.choose': () => {
          this.queue = null
          this.product = null
          this.dataObjects = {}
          this.data = {
            place: this.place,
            objects: [
              {
                type: 'message',
                data: {
                  position: 'bottom',
                  text: 'Choose queue'
                }
              },
              ...this.queues
            ]
          }
        },
        'storage.queue.task.print': () => {
          if (this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]) {
            return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
          }
        },
        'Orderadmin\\Storage\\Entity\\Assemblage\\Queue': this.handleQueueClick,
        'collection': this.handleItemClick,
        'storage.queue.task.reject': () => {
          this.data.event = 'storage.queue.task.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Assemblage_Task

          return this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.assembling.complete.box': () => {
          this.data.event = 'storage.assembling.complete.box'
          return this.handleBarcode({ value: '', raw: '', type: '' })
        }
      }

      if (actionsByType[item.type]) {
        return actionsByType[item.type](item)
      }

      if (!item.event) {
        return
      }

      this.data.entityClass = item.type
      this.data.event = item.event

      this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
    },
    reduceData (ignoredKeys = [], defaultValue = {}) {
      return Object.keys(this.data)
        .filter(key => !ignoredKeys.includes(key))
        .reduce((acc, key) => {
          return {
            ...acc,
            [key]: typeof this.data[key] === 'object'
              ? this.data[key].id
              : this.data[key]
          }
        }, { ...defaultValue })
    },
    handleAmount (e) {
      const value = Number(e)

      if (value < 1) {
        this.count = 1
      } else {
        this.count = value
      }
    },
    handleIncrease () {
      this.count += 1
    },
    handleDecrease () {
      if (this.count <= 1) {
        return
      }

      this.count -= 1
    },
    onFocus () {
      this.$emit('block')
    },
    onFocusOut () {
      this.$emit('unblock')
    }
  }
}
</script>

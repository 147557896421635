<template>
  <div>
    <component
        :is="components[rawData.entityClass]"
        v-bind="{ data, rawData }"
        @click="handleClick"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Components
import DynamicProductsCollection from './dynamic-products-collection'
import DynamicAssemblageTasksCollection from './dynamic-assemblage-tasks-collection'
import DynamicTasksCollection from './dynamic-tasks-collection.vue'
import DynamicMessageCollection from './dynamic-message-collection.vue'
import DynamicBundlesCollection from './dynamic-bundles-collection.vue'
import DynamicBundle from './dynamic-bundle.vue'

export default {
  name: 'DynamicTask',
  emits: ['click'],
  components: {
    DynamicProductsCollection,
    DynamicAssemblageTasksCollection,
    DynamicTasksCollection,
    DynamicMessageCollection,
    DynamicBundlesCollection,
    DynamicBundle,
    OptionsCollection: defineAsyncComponent(() => import('./options-collection.vue')),
    DynamicOfferCollection: defineAsyncComponent(() => import('./dynamic-offer-collection.vue')),
    OrderProductCollection: defineAsyncComponent(() => import('./order-product-collection.vue')),
    DynamicServiceCollection: defineAsyncComponent(() => import('./dynamic-service-collection.vue')),
    WarehousesCollection: defineAsyncComponent(() => import('./dynamic-warehouses-collection.vue')),
    DynamicPlacesCollection: defineAsyncComponent(() => import('./dynamic-places-collection.vue')),
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      components: {
        'Orderadmin\\Storage\\Picking\\Objects\\Message': 'dynamic-message-collection',
        'Orderadmin\\Storage\\Entity\\Picking\\Task': 'dynamic-tasks-collection',
        'Orderadmin\\Storage\\Entity\\Assemblage\\OrderProductTask': 'dynamic-products-collection',
        'Orderadmin\\Products\\Entity\\Order\\OrderProduct': 'order-product-collection',
        'Orderadmin\\Storage\\Entity\\Assemblage\\Task': 'dynamic-assemblage-tasks-collection',
        'Orderadmin\\Storage\\Assembling\\Objects\\Message': 'dynamic-message-collection',
        'Orderadmin\\Storage\\Tasks\\Objects\\Message': 'dynamic-message-collection',
        'Orderadmin\\Storage\\Assembling\\Objects\\Option': 'options-collection',
        'Orderadmin\\Products\\Entity\\Product\\Offer': 'dynamic-offer-collection',
        'Orderadmin\\Storage\\Entity\\Assemblage\\OrderProductBundleTask': 'dynamic-bundles-collection',
        'Orderadmin\\Products\\Entity\\Service': 'dynamic-service-collection',
        'Orderadmin\\Storage\\Entity\\Warehouse': 'warehouses-collection',
        'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest\\Place': 'dynamic-places-collection'
      }
    }
  },
  methods: {
    handleClick (data) {
      this.$emit('click', data)
    }
  }
}
</script>

<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t('Place') + ' ' + place.id }}
        </div>
      </q-card-section>

      <q-card-section v-if="isOpen">
        <div class="col-8 row q-pt-md items-center">
          <div class="col-sm-3 q-pl-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="placeSize.weight"
                :label="$t('Weight') + ', ' + appOptions.defaultDimensions.weightUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimensions($event, '')"
            />
          </div>

          <div class="col-sm-3 q-pl-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="placeSize.dimensions.x"
                :label="$t('Width') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimensions($event, 'x')"
            />
          </div>

          <div class="col-sm-3 q-pl-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="placeSize.dimensions.y"
                :label="$t('Height') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimensions($event, 'y')"
            />
          </div>

          <div class="col-sm-3 q-px-sm">
            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="placeSize.dimensions.z"
                :label="$t('Length') + ', ' + appOptions.defaultDimensions.dimensionUnit"
                :rules="[dimensionsValidation]"
                @focus="onFocus"
                @blur="onFocusOut"
                @update:model-value="handlePlaceDimensions($event, 'z')"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            class="q-mr-sm"
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            @click="handleOk"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Discard')"
            @click="hide"
        />
      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'

function createPlaceSize (id = null) {
  return {
    id,
    weight: null,
    dimensions: {
      x: null,
      y: null,
      z: null
    }
  }
}

export default {
  name: 'DeliveryRequestPlacesModal',
  props: {
    place: {
      type: Object,
      default () {
        return {
          id: ''
        }
      }
    }
  },
  data () {
    return {
      isOpen: false,
      isFocused: false,
      placeSize: createPlaceSize()
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    headerBackground () {
      return this.mode === 'measuring'
          ? 'bg-teal'
          : 'bg-warning'
    },
    dimensionsBackground () {
      return this.settings.consumption
          ? 'bg-teal'
          : 'bg-warning'
    },
    schema () {
      return {
        groups: [
          {
            id: 'form',
            fields: [
              {
                ref: 'consumption',
                type: 'multiselect',
                label: this.$t('Choose the shipping manifest'),
                field: 'consumption',
                wrapperStyleClasses: 'q-px-sm',
                value: this.settings.consumption,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return row.id + '/' + row.created.date.split(' ')[0] + ' (' + row._embedded.deliveryService.name + ')'
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'created', direction: 'desc' }
                    ],
                    filter: this.consumptionFilter,
                  }

                  return this.$service.deliveryServiceConsumption.getAll(query)
                },
                onFocus: () => {
                  this.onFocus()
                },
                onFocusOut: () => {
                  this.onFocusOut()
                },
                onChange: (consumption) => {
                  this.updateSettings({ consumption })

                  if (!consumption) {
                    this.isFirstAction = true
                  }
                },
                btnStyleClasses: 'q-px-xs',
                buttons: [
                  {
                    id: 'filter',
                    icon: 'filter_alt',
                    color: this.consumptionFilter.length > 0
                        ? 'teal'
                        : 'dark',
                    size: 'lg',
                    onClick: () => {
                      this.$refs.filterModal.show(this.consumptionFilter)
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    // Calculate overall weight when dr is mutliplace
    overallWeight () {
      if (!this.isMultiPlaceGood) {
        return this.deliveryRequest.weight || 0
      }

      return this.placeSizes.reduce((acc, place) => {
        return acc + Number(place.weight || 0)
      }, Number(this.placeSize.weight || 0))
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'addNotification'
    ]),
    resolve () {},
    reject () {},
    show () {
      // this.instruction = instruction
      this.isOpen = true

      this.$eventBus.update('catchAll', {
        "catchAll": false
      })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    hide () {
      this.isOpen = false

      this.$eventBus.update('catchAll', {
        "catchAll": true
      })
    },
    dimensionsValidation (val) {
      return val > 0 || val === null || 'Minimum value is 1'
    },
    placeSubmit () {
      if (
          this.placeSize.weight &&
          this.placeSize.weight > 0 &&
          this.placeSize.dimensions.x &&
          this.placeSize.dimensions.x > 0 &&
          this.placeSize.dimensions.y &&
          this.placeSize.dimensions.y > 0 &&
          this.placeSize.dimensions.z &&
          this.placeSize.dimensions.z > 0
      ) {
        return this.$service.deliveryServicePlace.save({ ...this.placeSize, id: undefined }, this.place.id)
            .then(() => {
              this.placeSizes.push(_.cloneDeep(this.placeSize))

              this.placeSize = this.deliveryRequest.places[this.placeSizes.length]
                  ? createPlaceSize(this.deliveryRequest.places[this.placeSizes.length].id)
                  : createPlaceSize()

              if (!this.placeSize.id) {
                return this.execute()
              }
            })
      }
    },
    handleOk () {
      return this.placeSubmit()
    },
    onFocus () {
      this.isFocused = true
    },
    onFocusOut () {
      this.isFocused = false
    },
    handlePlaceDimensions (value, type) {
      return this.handlePlaceMeasure({ type, value })
    },
    handlePlaceMeasure (barcode) {
      const actions = {
        X: value => {
          this.placeSize.dimensions.x = value
        },
        Y: value => {
          this.placeSize.dimensions.y = value
        },
        Z: value => {
          this.placeSize.dimensions.z = value
        },
      }

      if (barcode.type) {
        if (actions[barcode.type.toUpperCase()]) {
          actions[barcode.type.toUpperCase()](barcode.value)
        } else {
          this.addErrorNotification('Barcode type is not recognized')
        }
      } else {
        this.placeSize.weight = barcode.value
      }
    }
  }
}
</script>

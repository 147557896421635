<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card class="text-center">
      <q-card-section class="row q-px-xs">
        <div class="col text-h6 no-copy">
            {{ $t('Create ') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section>

          <form-builder :schema="schema"/>
        <div class="row q-pt-xs">


          <div class="col-12 text-center q-pa-sm">
            <q-btn
                no-caps
                unelevated
                style="min-width: 100px;"
                :disable="!model.warehouse || !model.place"
                color="teal"
                :label="$t('OK')"
                @click="handleSubmit"
            />
          </div>
        </div>
      </q-card-section>

    </q-card>

  </q-dialog>
</template>

<script>


import {mapMutations} from "vuex";

export default {
  name: 'CreateItemModal',
  emit: ['submit'],
  data () {
    return {
      isOpen: false,
      order: null,
      model: {
        warehouse: null,
        place: null,
        quantity: 0
      },
      value: 0
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.model.warehouse,
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {type: 'eq', field: 'state', value: 'active'},
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onChange: (warehouse) => {
                  this.model.warehouse = warehouse
                },
              },
              {
                type: 'multiselect',
                label: this.$t('Select a storage location'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'place',
                required: true,
                value: this.model.place,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    const path = this.loadPath(row)
                    return `${path.join(' - ')} (${row.code}*) (${row.type})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'normal'] }
                    ]
                  }

                  if(this.model.warehouse) {
                    const value = this.model.warehouse.type === 'virtual'
                      ? this.getId(this.model.warehouse._embedded.parent)
                      : this.model.warehouse.id

                    query.filter.push({ type: 'eq', field: 'warehouse', value })
                  }

                  return this.$service.storagePlace.getAll(query)
                },
                onChange: (place) => {
                  this.model.place = place
                }
              },
            ]
          }
        ]
      }
    },

  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    getId (entity = {}) {
      if (!entity) {
        return undefined
      }

      return entity.id
    },
    loadPath (place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [(place.placeAlias || '')]
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    },
    close () {
      this.isOpen = false
    },
    open (order = {}) {
      this.order = order
      this.isOpen = true
    },
    handleSubmit () {
      if( !this.model.warehouse) {
        this.addErrorNotification('No warehouse selected!')
        return
      }
      if( !this.model.place) {
        this.addErrorNotification('No place selected!')
        return
      }
      let data = {}

      if (this.order) {
        data = {
          warehouse: this.model.warehouse.id,
          place: this.model.place.id,
          sku: `P/O/${this.order.id}`,
          state: 'new'
        }
      } else {
        data = {
          warehouse: this.model.warehouse.id,
          place: this.model.place.id,
          state: 'new'
        }

      }

      this.$emit('submit', data)
      this.model.place = null
      this.model.warehouse = null
      this.order = null
      this.close()
    },
    onFocus () {
      this.$emit('click', { event: 'counter.focus' })
    },
    onFocusOut () {
      this.$emit('click', { event: 'counter.focusOut' })
    }
  }
}
</script>
<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 50vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Filters') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="hide"
        />
      </q-card-section>

      <q-card-section>
        <filter-collapse
          :is-open="isOpenFilter"
          :options="{
            defaultFilter: filters,
            fields: filterFields,
            style: {
              onHalf: true,
              flat: true,
              noGroups: true
            }
          }"
          @submit="submit"
          @close="hide"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import FilterCollapse from '../filters/FilterCollapse.vue'

export default {
  name: 'FilterModal',
  components: {
    FilterCollapse
  },
  props: {
    filterFields: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isOpenFilter: false,
      isOpen: false,
      filters: []
    }
  },
  methods: {
    show (filters) {
      this.filters = [...filters]
      this.isOpen = true

      this.$nextTick(() => {
        this.isOpenFilter = true
      })
    },
    hide () {
      this.isOpenFilter = false
      this.isOpen = false
      this.$emit('close')
    },
    submit (filter) {
      this.filters = filter
      this.$emit('submit', filter)
      this.hide()
    }
  }
}
</script>

<template>
  <div>
    <div class="q-px-md">
      <barcode-input
        :settings="{catchAll: false}"
        :out-focused="isFocused"
        @barcode="handleBarcode"
      />
    </div>

    <div style="height: 70vh; overflow: auto;">
      <div class="row">
        <div
          v-if="loadedOffers.length === 0 && !offersLoading"
          class="col-12 row fit q-py-lg justify-center items-center"
        >
          {{ $t('No Items') }}
        </div>

        <div
          v-for="item in loadedOffers"
          v-else
          :key="item.id"
          class="col-6 col-md-3 q-pa-sm"
        >
          <q-card
            class="cursor-pointer"
            @dblclick="handleAdd(item)"
          >
            <q-img
              :src="item.image || fallbackImage"
              :alt="item.name"
              :img-style="{ height: '200px' }"
              style="height: 180px;"
              basic
              @error="onImageLoadFailure($event)"
            >
              <div class="absolute-bottom text-h6">
                <div style="max-height: 32px; overflow: auto;">
                  {{ `${item.name} (${$t('ID')}: ${item.id})` }}
                </div>

                <div class="text-subtitle2">
                  {{ $t('Price') + ': ' + item.price }}
                </div>
              </div>
            </q-img>

            <q-card-section class="text-center text-caption wrap">
              {{ $t('Store') + `: ${item._embedded.shop.name}(${item._embedded.shop.id})` }}
            </q-card-section>

            <q-card-section class="text-center text-caption wrap overflow-auto">
              <items-table :items="item.items || []" />
            </q-card-section>
          </q-card>
        </div>
      </div>

      <div
        v-if="offersLoading"
        class="row fit items-center justify-center q-py-lg"
      >
        <q-spinner
          color="light-blue-9"
          size="3rem"
        />
      </div>

      <div
        v-show="hasInfinityElement"
        v-observe-visibility="loadItems"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import BarcodeInput from '../barcode-input/BarcodeInput.vue'
import ItemsTable from '../items-table/ItemsTable.vue'

export default {
  name: 'ProductTemplate',
  emits: ['submit'],
  components: {
    ItemsTable,
    BarcodeInput
  },
  directives: {
    ObserveVisibility
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      loadedOffers: [],
      hasInfinityElement: true,
      isFocused: false,
      isFirstPage: true
    }
  },
  computed: {
    ...mapGetters([
      'offers',
      'offersLoading',
      'offersPage',
      'offersTotalPages'
    ])
  },
  watch: {
    offers (newValue) {
      this.handleChange('offers')
    }
  },
  methods: {
    ...mapActions([
      'loadOffers'
    ]),
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleBarcode (barcode) {
      this.search = barcode.value
      this.loadNextItems(this.search, 1, 'offers')
    },
    handleFocus (isFocused) {
      this.isFocused = isFocused
    },
    loadItems () {
      if (this.offersLoading) {
        return
      }

      if (this.isFirstPage) {
        this.loadNextItems(this.search, 1, 'offers')
        this.isFirstPage = false
        return
      }

      if (this.offersPage < this.offersTotalPages) {
        this.loadNextItems(this.search, this.offersPage + 1, 'offers')
      }
    },
    handleAdd (item) {
      this.$emit('submit', item)
    },
    createQuery (params) {
      const query = {
        page: 1,
        per_page: 25,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ],
        filter: [],
        ...params
      }

      return query
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        offers: this.loadOffers
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery({ search, page })

      return loaders[loader](query)
    },
    handleChange (type) {
      const types = {
        offers: {
          getter: 'offers',
          page: 'offersPage',
          loadedValue: 'loadedOffers'
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    }
  }
}
</script>

<template>
  <div
    :class="`card border-0 rounded picking-item ${bgClass} shadow text-dark`"
    @click="handleClick"
  >
    <div
      class="row justify-between full-width border-bottom q-pb-xs q-mb-xs q-pt-sm q-px-sm"
      :style="isOpen ? 'opacity: .4;' : ''"
    >
      <div>
        <strong>{{ $t('ID') + ': ' + data.id }}</strong>

        <q-icon
          v-if="sequences.length > 0"
          class="q-ml-sm"
          :name="isOpen ? 'expand_less' : 'expand_more'"
        />
      </div>

      <div>
        <strong>{{ $t('Tasks') + ': ' + tasks }}</strong>
      </div>
    </div>

    <div
      class="text-center q-pb-sm"
      :style="isOpen ? 'opacity: .4;' : ''"
    >
      {{ data.name }}
    </div>

    <q-slide-transition>
      <div
        v-if="sequences && sequences.length > 0 && isOpen"
        class="border-top q-mt-sm rounded"
      >
        <div
          v-for="sequence in sequences"
          :key="sequence.id"
          class="q-pa-sm clickable border-bottom rounded"
          @click="handleSequence(sequence)"
        >
          <div class="row justify-between">
            <div>
              <div class="strong text-weight-bold">
                {{ sequence.id + '/' + sequence.extId }}
              </div>

              <div>
                {{ sequence.state }}
              </div>
            </div>

            <div class="strong text-weight-bold text-right">
              <div>
                {{ $t('Tasks') + ': ' + sequence.count }}
              </div>

              <div>
                {{ sequence.taskStates.map(task => $t(task.state) + ': ' + task.count).join(', ') }}
              </div>
            </div>
          </div>
        </div>

        <div class="q-pa-sm text-caption text-center">
          {{ $t('Please, choose sequence') }}
        </div>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PickingQueue',
  emits: ['click'],
  props: {
    data: {
      type: Object,
      default () {
        return {
          options: []
        }
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    sequences () {
      return (this.data.sequences || []).filter(x => {
        if (x && x.state === 'processing') {
          return x
        }

        return x && x.count && x.count > 0
      })
    },
    tasks () {
      if (this.data.tasks) {
        return this.data.tasks
      }

      return (this.data.options || [])
        .filter(val => val)
        .reduce((acc, val) => acc + Number(val.count), 0)
    },
    bgClass () {
      return this.tasks > 0
        ? 'picking-green'
        : 'picking-warning'
    }
  },
  methods: {
    handleSequence (sequence) {
      this.$emit('click', { ...this.rawData, sequence })
    },
    handleClick () {
      if (this.sequences && this.sequences.length > 0) {
        this.isOpen = !this.isOpen
        return
      }

      const data = {
        ...this.rawData,
        sequence: this.rawData.sequences
          ? this.rawData.sequences[0]
          : undefined
      }

      this.$emit('click', data)
    }
  }
}
</script>

<style>
.picking-green {
  background-color: #c6ff54 !important;
}

.picking-warning {
  background-color: #ffff7f !important;
}
</style>

<template>
  <q-dialog
    v-model="isOpen"
    @hide="close"
  >
    <q-card style="min-width: 30vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Printer settings') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-toggle
          v-model="settings.forceModal"
          class="q-mb-sm"
          :label="$t('Force show a printing modal')"
        />

        <q-toggle
          v-model="settings.autoclose"
          class="q-mb-sm"
          :label="$t('Autoclose print window')"
        />

        <q-input
          v-model="settings.timeout"
          standout="bg-teal text-white"
          class="q-mb-lg"
          type="number"
          :disable="!settings.autoclose"
          :rules="[val => val >= 0 || $t('Minimum timeout is 0!')]"
          :label="$t('Timeout before close')"
          :hint="$t('How much time to wait before closing of the printing modal')"
        />

        <q-btn
          v-if="!isConnected"
          color="warning"
          class="full-width"
          :label="$t('Try to reconnect')"
          @click="reconnect"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'PrinterSettingsModal',
  emits: ['close'],
  data () {
    return {
      isOpen: false,
      isConnected: false,
      printerSub: null,
      settings: { ...this.$service.printer.settings }
    }
  },
  mounted () {
    this.printerSub = this.$service.printer._printer.subscribe('stateChange', (isConnected) => {
      this.isConnected = isConnected
    })
  },
  unmounted () {
    this.printerSub.unsubscribe()
  },
  methods: {
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.$emit('close')
    },
    reconnect () {
      this.$service.printer._printer.connect()
    },
    save () {
      this.$service.printer.updateSettings(this.settings)
      this.close()
    }
  }
}
</script>

<template>
  <div v-if="hasError" class="text-negative text-center text-subtitle1">
    {{ $t('Format of received task is wrong. Please connect with support!')}}
  </div>

  <div v-else class="border rounded">
    <div
      v-for="(item, i) in data"
      :key="item.id"
      class="row justify-center items-stretch"
      :class="`${getClassName(item)} ${i < data.length - 1 && data.length > 1 ? 'border-bottom' : ''}`"
    >
      <div
        class="q-px-md q-my-xs text-center text-h6 border-right row items-center text-bold"
        style="min-height: 100%;"
      >
        {{ item.scannedCount + '/' + item._embedded.orderProduct.count }}
      </div>

      <div class="col q-px-xs row justify-center items-center">
        <!-- <div class="col-12 col-md-4 col-lg-3 q-pa-xs">
          <new-picking-place
            v-if="item._embedded && item._embedded.place"
            :data="item._embedded.place"
          />
        </div>

        <div
          class="col-4 col-md-2 col-lg-2 q-py-xs row justify-center items-center"
          @click="handleImageClick(item._embedded.orderProduct.productOfferRaw)"
        >
          <img
            style="height: 76px; width: 100%; object-fit: contain;"
            :src="item._embedded.orderProduct.productOfferRaw.image || fallbackImage"
            :alt="item._embedded.orderProduct.productOfferRaw.name"
            @error="onImageLoadFailure"
          >
        </div>

        <div class="col-4 col-md-4 col-lg-4 text-center">
          {{ item._embedded.orderProduct.productOfferRaw.name }} <span>({{ $t('ID') + ': ' + item._embedded.orderProduct.productOfferRaw.id }})</span>
        </div>

        <div
          class="col-4 col-md-2 col-lg-3 text-center"
          @dblclick="handleClick(item)"
        >
          {{ $t('UPC') + ': ' + getBarcode(item).join(', ') }}
        </div> -->

        <div
          v-for="(child, i) in item.childTasks"
          :key="child.id"
          class="row clickable justify-center items-stretch col-12 border-top"
          :class="`${getClassName(child)} ${i < data.length - 1 && data.length > 1 ? 'border-bottom' : ''}`"
        >
          <div
            class="q-px-md q-my-xs text-center text-h6 border-right row items-center text-bold"
            style="min-height: 100%;"
          >
            {{ child.scannedCount + '/' + child.count }}
          </div>

          <div class="col q-px-xs row justify-center items-center">
            <div
              class="col-4 col-md-2 col-lg-2 q-py-xs row justify-center items-center"
              @click="handleImageClick(child._embedded.orderProduct.productOfferRaw)"
            >
              <img
                style="height: 76px; width: 100%; object-fit: contain;"
                :src="child._embedded.orderProduct.productOfferRaw.image || fallbackImage"
                :alt="child._embedded.orderProduct.productOfferRaw.name"
                @error="onImageLoadFailure"
              >
            </div>

            <div class="col-4 col-md-4 col-lg-4 text-center">
              {{ child._embedded.orderProduct.productOfferRaw.name }} <span>({{ $t('ID') + ': ' + child._embedded.orderProduct.productOfferRaw.id }})</span>
            </div>

            <div
              class="col-4 col-md-2 col-lg-3 text-center"
              @dblclick="handleClick(child)"
            >
              {{ $t('UPC') + ': ' + getBarcode(child).join(', ') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <portal to="assembling-items">
      <div class="text-h6 text-bold">
        {{ `${details.items} ${$t(`item${details.items > 1 ? 's' : ''}`)} (${details.products} ${$t(`SKU${details.products > 1 ? 's' : ''}`)})` }}
      </div>
    </portal>

    <q-dialog
      v-model="isOpenImage"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card
        class="row items-center justify-center"
        style="background: rgba(0, 0, 0, .7);"
        @click="handleImage"
      >
        <img
          style="height: 90vh; width: 90vw; object-fit: contain;"
          :src="image || fallbackImage"
          @error="onImageLoadFailure"
        >
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Components
import newPickingPlace from '../picking/new-picking-place.vue'

export default {
  name: 'DynamicBundlesCollection',
  emits: ['click'],
  components: {
    newPickingPlace
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpenImage: false,
      image: null,
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    hasError () {
      return this.data.find(x => !x._embedded || !x._embedded.orderProduct)
    },
    details () {
      return {
        items: this.data.reduce((acc, item) => {
          if (!item._embedded || !item._embedded.orderProduct) {
            return acc
          }

          return acc + Number(item.count)
        }, 0),
        products: this.data.length
      }
    }
  },
  methods: {
    getClassName (offer) {
      if (offer.scannedCount > 0 && offer.scannedCount < offer.count) {
        return 'bg-warning'
      }

      if (offer.scannedCount >= offer.count) {
        return 'bg-positive'
      }

      return ''
    },
    handleImageClick (offer) {
      this.image = offer.image || this.fallbackImage
      this.isOpenImage = true
    },
    handleImage () {
      this.isOpenImage = false
      this.image = null
    },
    getBarcode (product) {
      if (product.instructions) {
        return (product.instructions.find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
      }

      if (product._embedded.orderProduct.productOfferRaw && Array.isArray(product._embedded.orderProduct.productOfferRaw.barcodes)) {
        return product._embedded.orderProduct.productOfferRaw.barcodes
      }

      return []
    },
    handleClick (data) {
      const item = {
        type: this.rawData.type,
        event: this.rawData.event,
        data
      }

      this.$emit('click', item)
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getColor (item) {
      if (item.scannedCount === 0) {
        return 'danger'
      }

      if (item.scannedCount === item.count) {
        return 'green'
      }

      return 'yellow'
    }
  }
}
</script>

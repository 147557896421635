<template>
  <div
    class="assembling-hints text-center full-width text-h6"
    :class="data[0] && data[0].data.position ? `absolute-${data[0].data.position}`: 'absolute-bottom'"
  >
    <p
      v-for="(message, i) in data"
      :key="i"
      class="rounded"
      :class="message.data.bg ? 'bg-' + message.data.bg : ''"
    >
      <div v-if="typeof message.data.text === 'string'">
        {{ $t(message.data.text) }}
      </div>
    </p>
  </div>
</template>

<script>
export default {
  name: 'DynamicMessageCollection',
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style>
.assembling-hints {
  animation: fadein 1.5s infinite;
}
</style>

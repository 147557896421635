<template>
  <q-dialog
    v-model="isOpen"
    persistent
    class="modal-lg"
  >
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Print and Go') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="handleClose"
        />
      </q-card-section>
      <measuring
        :outside-delivery-request="options.data.deliveryRequest"
        @submit="handleOk"
      />
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import Measuring from '../../pages/delivery-manager/Measuring.vue'

export default {
  name: 'MeasuringFastModal',
  components: {
    Measuring
  },
  data () {
    return {
      isOpen: false,
      options: {}
    }
  },
  methods: {
    resolve () {},
    reject () {},
    show (options = {}) {
      this.options = {
        ...this.options,
        ...options
      }

      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(false)
    },
    handleOk () {
      this.isOpen = false
      this.resolve(true)
    }
  }
}
</script>

<template>
  <q-dialog v-model="isOpen" class="modal-lg">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('One-time service') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="grey-10"
          text-color="grey-10"
          class="q-mr-sm"
          :label="$t('Discard')"
          outline
          no-caps
          @click="close"
        />

        <q-btn
          color="green-6"
          text-color="white"
          :label="$t('Create')"
          no-caps
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ServiceModal',
  data () {
    return {
      model: {},
      percent: 0,
      total: 0,
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
        'isClient'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.model.name,
                field: 'name',
                label: this.$t('Name'),
                wrapperStyleClasses: this.isClient? 'col-12 col-md-6 q-pa-xs' : 'col-12 col-md-4 q-pa-xs',
                required: true,
                onChange: name => {
                  this.model.name = name
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.model.count,
                field: 'count',
                label: this.$t('Quantity'),
                wrapperStyleClasses: this.isClient? 'col-12 col-md-6 q-pa-xs' : 'col-12 col-md-2 q-pa-xs',
                required: true,
                onChange: count => {
                  this.model.count = count
                }
              },
              {
                if: !this.isClient,
                type: 'input',
                inputType: 'number',
                value: this.model.price,
                field: 'price',
                label: this.$t('Price'),
                wrapperStyleClasses: 'col-12 col-md-2 q-pa-xs',
                required: true,
                onChange: price => {
                  this.model.price = price
                }
              },
              {
                if: !this.isClient,
                type: 'input',
                inputType: 'number',
                value: this.percent,
                field: 'percent',
                label: this.$t('%(±)'),
                disabled: !this.model.price,
                wrapperStyleClasses: 'col-12 col-md-2 q-pa-xs',
                onChange: percent => {
                  this.percent = percent
                  this.handleTotal()
                }
              },
              {
                if: !this.isClient,
                type: 'input',
                inputType: 'number',
                value: this.total,
                field: 'total',
                disabled: true,
                label: this.$t('Total'),
                wrapperStyleClasses: 'col-12 col-md-2 q-pa-xs',
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleTotal () {
      this.total = ((this.model.price * (100 + this.percent)) / 100 ).toFixed(2)
    },
    save () {
      if (this.total) {
        this.model.price = this.total
        this.$emit('on-submit', this.model)
        this.total = 0
        this.percent = 0
        this.close()
      } else {
        this.$emit('on-submit', this.model)
        this.close()
      }

    },
    close () {
      this.isOpen = false
    },
    open () {
      this.isOpen = true
      this.model = {
        name: null,
        count: null,
        price: null
      }
    }
  }
}
</script>
